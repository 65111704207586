import React from 'react'
import { useTranslation } from 'react-i18next'
import { Coupon as CouponType } from 'common/types/CouponType'
import { PricePlan as PricePlanType } from 'common/types/OfferInterface'
import {
  ProductBumpInfoInterface,
  ProductInfoInterface,
} from 'common/types/entities/ProductInterface'
import {
  centsToEuro,
  getNetPrice,
  getPricePlanAmount,
  getReadablePrice,
} from 'common/utils/priceCalculator'
import DividerUi from '../ui/DividerUi'
import PricePlanAmountUi from '../ui/PricePlanAmountUi'
import PricePlanDescriptionUi from '../ui/PricePlanDescriptionUi'
import PricePlanItemUi from '../ui/PricePlanItemUi'
import PricePlanUi from '../ui/PricePlanUi'

interface ProductBumpTotalProps {
  activePricePlan?: PricePlanType
  quantity?: number
  coupon?: CouponType
  tax: number
  productBumpInfo: ProductBumpInfoInterface
  productInfo?: ProductInfoInterface
  locale: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
}

function ProductBumpTotal({
  activePricePlan,
  quantity = 1,
  coupon,
  tax,
  productBumpInfo,
  productInfo,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
}: ProductBumpTotalProps) {
  const { t } = useTranslation(['common', 'publisher'])
  return (
    <>
      <DividerUi />
      <PricePlanUi>
        <PricePlanItemUi skipLeftOffset={!activePricePlan}>
          <PricePlanDescriptionUi
            fontSize={descriptionFontSize}
            mobileFontSize={mobileDescriptionFontSize}
            fontStyle={descriptionFontStyle}
            mobileFontStyle={mobileDescriptionFontStyle}
            fontWeight={descriptionFontWeight}
            mobileFontWeight={mobileDescriptionFontWeight}
            fontFamily={descriptionFontFamily}
            mobileFontFamily={mobileDescriptionFontFamily}
            lineHeight={descriptionLineHeight}
            mobileLineHeight={mobileDescriptionLineHeight}
            color={descriptionColor}
            data-test-id={`offer-price-product-bump-total-${productBumpInfo.name}`}
          >
            {t(
              'components.offer_price.one_shots_summary.total_amount_with_tax',
            )}
          </PricePlanDescriptionUi>
          {activePricePlan ? (
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
            >{`${getReadablePrice(
              centsToEuro(
                getNetPrice(
                  getPricePlanAmount(activePricePlan),
                  quantity,
                  coupon,
                ) +
                  tax * quantity +
                  productBumpInfo.priceAmount * 100,
              ),
              activePricePlan.currency,
              locale,
            )}`}</PricePlanAmountUi>
          ) : (
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
            >{`${productInfo?.currencySign}  ${
              (Math.round((productInfo?.netPrice || 0) * 100) +
                Math.round(productBumpInfo.priceAmount * 100)) /
              100
            }`}</PricePlanAmountUi>
          )}
        </PricePlanItemUi>
      </PricePlanUi>
    </>
  )
}

export default ProductBumpTotal
