import axios from 'axios'

export type RazorpayBuyOfferResponse = {
  rpOrderId?: string
  rpBumpOrderId: string
  rpSubscriptionId?: string
  rpBumpSubscriptionId: string
  redirectUrl: string
  vendorName: string
  keyId: string
  prefill: {
    name: string
    email: string
    phone: string
    address: string
  }
}

export function buyMainOffer(
  pageId: number,
  purchaseProcessId: number,
  body: any,
) {
  return axios.post<RazorpayBuyOfferResponse>(
    `/secure/razorpay/${pageId}/buy-main-offer/${purchaseProcessId}`,
    body,
  )
}

export function buyUpsell(
  pageId: number,
  purchaseProcessId: number,
  body: any,
) {
  return axios.post<RazorpayBuyOfferResponse>(
    `/secure/razorpay/${pageId}/buy-upsell/${purchaseProcessId}`,
    body,
  )
}

export function buyBump(pageId: number, purchaseProcessId: number, body: any) {
  return axios.post<RazorpayBuyOfferResponse>(
    `/secure/razorpay/${pageId}/buy-bump/${purchaseProcessId}`,
    body,
  )
}
