import { PageLocaleEnum } from './PageLocaleEnum'

// ISO 639-1
export enum LanguageCodeEnum {
  FRENCH = 'fr',
  ENGLISH = 'en',
  SPANISH = 'es',
  ITALIAN = 'it',
  PORTUGUESE = 'pt',
  DEUTSCH = 'de',
  DUTCH = 'nl',
  RUSSIAN = 'ru',
  JAPANESE = 'ja',
  ARABIC = 'ar',
  TURKISH = 'tr',
  CHINESE = 'zh',
}

export function getLanguageCodeByLocale(locale: PageLocaleEnum) {
  switch (locale) {
    case PageLocaleEnum.FRENCH:
      return LanguageCodeEnum.FRENCH
    case PageLocaleEnum.ENGLISH:
      return LanguageCodeEnum.ENGLISH
    case PageLocaleEnum.SPANISH:
      return LanguageCodeEnum.SPANISH
    case PageLocaleEnum.ITALIAN:
      return LanguageCodeEnum.ITALIAN
    case PageLocaleEnum.PORTUGUESE:
      return LanguageCodeEnum.PORTUGUESE
    case PageLocaleEnum.DUTCH:
      return LanguageCodeEnum.DUTCH
    case PageLocaleEnum.RUSSIAN:
      return LanguageCodeEnum.RUSSIAN
    case PageLocaleEnum.JAPANESE:
      return LanguageCodeEnum.JAPANESE
    case PageLocaleEnum.DEUTSCH:
      return LanguageCodeEnum.DEUTSCH
    case PageLocaleEnum.ARABIC:
      return LanguageCodeEnum.ARABIC
    case PageLocaleEnum.CHINESE:
      return LanguageCodeEnum.CHINESE
    case PageLocaleEnum.TURKISH:
      return LanguageCodeEnum.TURKISH
  }
}
