import React from 'react'
import { useTranslation } from 'react-i18next'
import { OneShotPlan } from 'common/types/OfferInterface'
import { ProductInfoInterface } from 'common/types/entities/ProductInterface'
import DividerUi from '../ui/DividerUi'
import PricePlanAmountUi from '../ui/PricePlanAmountUi'
import PricePlanDescriptionUi from '../ui/PricePlanDescriptionUi'
import PricePlanItemWithoutLeftOffsetUi from '../ui/PricePlanItemWithoutLeftOffsetUi'

interface ProductAndPricePlanBumpTotalProps {
  productInfo?: ProductInfoInterface
  bumpPlan: OneShotPlan
  bumpTax: number
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
}

function ProductAndPricePlanBumpTotal({
  productInfo,
  bumpPlan,
  bumpTax,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
}: ProductAndPricePlanBumpTotalProps) {
  const { t } = useTranslation(['common', 'publisher'])

  return (
    <>
      <DividerUi />
      <PricePlanItemWithoutLeftOffsetUi>
        <PricePlanDescriptionUi
          fontSize={descriptionFontSize}
          mobileFontSize={mobileDescriptionFontSize}
          fontStyle={descriptionFontStyle}
          mobileFontStyle={mobileDescriptionFontStyle}
          fontWeight={descriptionFontWeight}
          mobileFontWeight={mobileDescriptionFontWeight}
          fontFamily={descriptionFontFamily}
          mobileFontFamily={mobileDescriptionFontFamily}
          lineHeight={descriptionLineHeight}
          mobileLineHeight={mobileDescriptionLineHeight}
          color={descriptionColor}
          data-test-id={`offer-price-product-bump-total-tax-${productInfo?.name}`}
        >
          {t('components.offer_price.one_shots_summary.total_amount_with_tax')}
        </PricePlanDescriptionUi>
        <PricePlanAmountUi
          fontSize={amountFontSize}
          mobileFontSize={mobileAmountFontSize}
          fontStyle={amountFontStyle}
          mobileFontStyle={mobileAmountFontStyle}
          fontWeight={amountFontWeight}
          mobileFontWeight={mobileAmountFontWeight}
          fontFamily={amountFontFamily}
          mobileFontFamily={mobileAmountFontFamily}
          lineHeight={amountLineHeight}
          mobileLineHeight={mobileAmountLineHeight}
          color={amountColor}
        >{`${productInfo?.currencySign}  ${
          ((productInfo?.netPrice || 0) * 100 +
            bumpPlan.directChargeAmount +
            bumpTax) /
          100
        }`}</PricePlanAmountUi>
      </PricePlanItemWithoutLeftOffsetUi>
    </>
  )
}

export default ProductAndPricePlanBumpTotal
