import { captchaPlaceholder } from 'publisher/reducers/managementReducer'
import { ManagementState } from 'publisher/store/management/managementStateInterface'

function isScreenshotMode(state: ManagementState) {
  return state.isScreenshotMode
}

function getUrl(state: ManagementState) {
  return state.url
}

function isDesktop(state: ManagementState) {
  return !state.isMobile
}

function isPreview(state: ManagementState) {
  return state.previewMode === true
}

function getConfirmedExplicitConsentsIds(state: ManagementState) {
  return state.confirmedExplicitConsents
}

function getCaptcha(state: ManagementState) {
  return state.captcha === captchaPlaceholder ? null : state.captcha
}

const managementSelectors = {
  isScreenshotMode,
  isDesktop,
  getUrl,
  isPreview,
  getConfirmedExplicitConsentsIds,
  getCaptcha,
}

export default managementSelectors
