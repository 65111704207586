import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProductBumpInfoInterface } from 'common/types/entities/ProductInterface'
import DividerUi from '../ui/DividerUi'
import PricePlanAmountUi from '../ui/PricePlanAmountUi'
import PricePlanItemUi from '../ui/PricePlanItemUi'
import PricePlanNameUi from '../ui/PricePlanNameUi'
import PricePlanUi from '../ui/PricePlanUi'

interface ProductBumpProps {
  productBumpInfo: ProductBumpInfoInterface
  nameFontFamily?: string
  mobileNameFontFamily?: string
  nameFontSize: number
  mobileNameFontSize?: number
  nameFontStyle?: string
  mobileNameFontStyle?: string
  nameFontWeight?: string
  mobileNameFontWeight?: string
  nameLineHeight: number
  mobileNameLineHeight?: number
  nameColor: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  skipLeftOffset: boolean
}

function ProductBump({
  productBumpInfo,
  nameFontSize,
  mobileNameFontSize,
  nameFontStyle,
  mobileNameFontStyle,
  nameFontWeight,
  mobileNameFontWeight,
  nameFontFamily,
  mobileNameFontFamily,
  nameLineHeight,
  mobileNameLineHeight,
  nameColor,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  skipLeftOffset,
}: ProductBumpProps) {
  const { t } = useTranslation(['common', 'publisher'])
  const needTotal =
    productBumpInfo && productBumpInfo.name && !!productBumpInfo.taxPercent

  return (
    <>
      <DividerUi />
      <PricePlanUi>
        <PricePlanItemUi skipLeftOffset={skipLeftOffset}>
          <PricePlanNameUi
            fontSize={nameFontSize}
            mobileFontSize={mobileNameFontSize}
            fontStyle={nameFontStyle}
            mobileFontStyle={mobileNameFontStyle}
            fontWeight={nameFontWeight}
            mobileFontWeight={mobileNameFontWeight}
            fontFamily={nameFontFamily}
            mobileFontFamily={mobileNameFontFamily}
            lineHeight={nameLineHeight}
            mobileLineHeight={mobileNameLineHeight}
            color={nameColor}
            data-test-id={`offer-price-product-bump-${productBumpInfo.name}`}
          >
            {productBumpInfo.name}
          </PricePlanNameUi>
          <PricePlanAmountUi
            fontSize={amountFontSize}
            mobileFontSize={mobileAmountFontSize}
            fontStyle={amountFontStyle}
            mobileFontStyle={mobileAmountFontStyle}
            fontWeight={amountFontWeight}
            mobileFontWeight={mobileAmountFontWeight}
            fontFamily={amountFontFamily}
            mobileFontFamily={mobileAmountFontFamily}
            lineHeight={amountLineHeight}
            mobileLineHeight={mobileAmountLineHeight}
            color={amountColor}
          >
            {`${productBumpInfo.currencySign}  ${productBumpInfo.priceWithoutTaxAndCurrency}`}
          </PricePlanAmountUi>
        </PricePlanItemUi>
        {needTotal && (
          <>
            <PricePlanItemUi skipLeftOffset={skipLeftOffset}>
              <PricePlanNameUi
                fontSize={nameFontSize}
                mobileFontSize={mobileNameFontSize}
                fontStyle={nameFontStyle}
                mobileFontStyle={mobileNameFontStyle}
                fontWeight={nameFontWeight}
                mobileFontWeight={mobileNameFontWeight}
                fontFamily={nameFontFamily}
                mobileFontFamily={mobileNameFontFamily}
                lineHeight={nameLineHeight}
                mobileLineHeight={mobileNameLineHeight}
                color={nameColor}
              >
                {t('components.offer_price.vat')}
              </PricePlanNameUi>
              <PricePlanAmountUi
                fontSize={amountFontSize}
                mobileFontSize={mobileAmountFontSize}
                fontStyle={amountFontStyle}
                mobileFontStyle={mobileAmountFontStyle}
                fontWeight={amountFontWeight}
                mobileFontWeight={mobileAmountFontWeight}
                fontFamily={amountFontFamily}
                mobileFontFamily={mobileAmountFontFamily}
                lineHeight={amountLineHeight}
                mobileLineHeight={mobileAmountLineHeight}
                color={amountColor}
              >
                {`${productBumpInfo.currencySign}  ${productBumpInfo.taxAmount}`}
              </PricePlanAmountUi>
            </PricePlanItemUi>
            <PricePlanItemUi skipLeftOffset={skipLeftOffset}>
              <PricePlanNameUi
                fontSize={nameFontSize}
                mobileFontSize={mobileNameFontSize}
                fontStyle={nameFontStyle}
                mobileFontStyle={mobileNameFontStyle}
                fontWeight={nameFontWeight}
                mobileFontWeight={mobileNameFontWeight}
                fontFamily={nameFontFamily}
                mobileFontFamily={mobileNameFontFamily}
                lineHeight={nameLineHeight}
                mobileLineHeight={mobileNameLineHeight}
                color={nameColor}
              >
                {t('components.offer_price.total')}
              </PricePlanNameUi>
              <PricePlanAmountUi
                fontSize={amountFontSize}
                mobileFontSize={mobileAmountFontSize}
                fontStyle={amountFontStyle}
                mobileFontStyle={mobileAmountFontStyle}
                fontWeight={amountFontWeight}
                mobileFontWeight={mobileAmountFontWeight}
                fontFamily={amountFontFamily}
                mobileFontFamily={mobileAmountFontFamily}
                lineHeight={amountLineHeight}
                mobileLineHeight={mobileAmountLineHeight}
                color={amountColor}
              >
                {productBumpInfo.price}
              </PricePlanAmountUi>
            </PricePlanItemUi>
          </>
        )}
      </PricePlanUi>
    </>
  )
}
export default ProductBump
