import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { PaymentFormStepTypeEnum } from 'common/enums/PaymentFormStepTypeEnum'
import { mercadoCreditCardTypes } from 'common/enums/PaymentMethodEnum'
import { PricePlanTypeEnum } from 'common/enums/PricePlanTypeEnum'
import {
  getPricePlanAmount,
  getPriceWithCoupon,
} from 'common/utils/priceCalculator'
import { getVat } from 'common/utils/taxCalculator'
import { getCustomerCountry } from 'publisher/reducers/optInReducer'
import { getFieldValueBySlug } from 'publisher/store/optIn/optInSelectors'
import loadScript from 'publisher/utils/loadScript'
import {
  managementSelectors,
  typedPageSelectors,
  useManagement,
  useOptIn,
  usePage,
  usePayment,
} from '../store'
import paymentSelectors, {
  getActivePricePlan,
  getMercadoPagoPublicKey,
  getOfferOwnerCountry,
  getPaymentMethods,
  isOnlyPersonalAvailable,
} from '../store/payment/paymentSelectors'

type InstallmentsParams = {
  amount: string
  locale?: string
  bin: string
  processingMode: string
}

export type MercadoPago = {
  cardForm: (e: any) => any
  getInstallments: (params: InstallmentsParams) => Promise<any>
}

type CardData = {
  token: string
  installments: string
  paymentMethodId: string
  issuerId: string
  identificationType: string
  identificationNumber: string
  processingMode: string
  merchantAccountId?: string
}

type CardForm = {
  createCardToken: () => Promise<{ token: string }>
  mount: () => void
  unmount: () => void
  getCardFormData: () => Promise<CardData>
  submit: () => void
}

export type MercadoPagoServerError = {
  cause: { code: string; description: string }[]
  error: string
  message: string
  status: number
}

type MercadoPageFormError = {
  code: string
  message: string
}

export type MercadoPagoWrapper = {
  cardForm: CardForm | null
  errors: Record<string, string>
  resetError: (key: string) => void
  setErrors: (errors: Record<string, string>) => void
  areInstallmentsVisible: boolean
  createCardToken: () => Promise<any>
}

interface Constructable<T> {
  new (key: string, options?: { locale: string }): T
}

declare global {
  interface Window {
    MercadoPago: Constructable<MercadoPago>
    Rollbar?: {
      error: (name: string, payload?: any) => void
      info: (name: string, payload?: any) => void
      captureEvent: (metadata: Record<string, any>, level: string) => void
    }
  }
}

export const formOptions = {
  id: 'form-checkout',
  cardholderName: {
    id: 'mercado-card-holder-name',
  },
  cardNumber: {
    id: 'mercado-card-number',
  },
  cardExpirationMonth: {
    id: 'mercado-card-expiration-month',
  },
  cardExpirationYear: {
    id: 'mercado-card-expiration-year',
  },
  securityCode: {
    id: 'mercado-card-security_code',
  },
  installments: {
    id: 'mercado-card-installments',
  },
  issuer: {
    id: 'form-checkout__issuer',
  },
  identificationType: {
    id: 'mercado-card-identification-type',
  },
  identificationNumber: {
    id: 'mercado-card-identification-number',
  },
}

function getFieldError(errorCode: string) {
  switch (errorCode) {
    case '205':
    case 'E301':
      return { field: 'cardNumber', message: 'validation.card_number_invalid' }
    case '209':
    case '326':
    case '208':
    case '325':
    case 'E205':
      return {
        field: 'cardExpiration',
        message: 'validation.expiration_invalid',
      }
    case '221':
    case '316':
      return {
        field: 'cardholderName',
        message: 'validation.cardholder_name_invalid',
      }
    case '214':
    case '324':
      return {
        field: 'identificationNumber',
        message: 'validation.identification_number_invalid',
      }
    case '224':
      return {
        field: 'securityCode',
        message: 'validation.cvc_invalid',
      }
    default:
      console.error('undefined mercado pago error code', errorCode)
      return {
        field: '',
        message: '',
      }
  }
}

const getCardFormData = async (
  delay: number,
  cardForm: CardForm,
): Promise<CardData> => {
  await new Promise(resolve => setTimeout(resolve, delay))
  await cardForm.createCardToken()
  return cardForm.getCardFormData()
}

const MercadoPagoContext = createContext<MercadoPagoWrapper>(
  {} as MercadoPagoWrapper,
)

export const useMercadoPago = () => useContext(MercadoPagoContext)

export function MercadoPagoProvider(
  props: React.PropsWithChildren<Record<string, never>>,
) {
  const { t } = useTranslation('publisher')
  const paymentMethods = usePayment(getPaymentMethods)
  const mercadoPagoPublicKey = usePayment(getMercadoPagoPublicKey)
  const pricePlan = usePayment(getActivePricePlan)
  const [mercadoPago, setMercadoPago] = useState<MercadoPago | null>(null)
  const [cardForm, setCardForm] = useState<CardForm | null>(null)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const product = usePayment(paymentSelectors.getProduct)
  const activeVariant = usePayment(paymentSelectors.getProductActiveVariant)
  const selectedProductPrice = activeVariant?.price
    ? activeVariant?.price
    : product?.id
    ? product.price
    : 0
  const pricePlanAmount = pricePlan ? getPricePlanAmount(pricePlan) : 0
  const coupon = usePayment(paymentSelectors.getCheckedCoupon)
  const productQuantity = usePayment(paymentSelectors.getProductQuantity)

  const isDesktop = useManagement(managementSelectors.isDesktop)
  const twoStepPaymentFormStepType = usePage(p =>
    typedPageSelectors.getVisibleTwoStepPaymentFormOptInStep(p, isDesktop),
  )
  const paymentStep = usePayment(paymentSelectors.getTwoStepPaymentFormStepType)
  const offerOwnerCountry = usePayment(getOfferOwnerCountry)
  const customerCountry = useOptIn(
    state =>
      getFieldValueBySlug(state, FieldSlugEnum.Country) ||
      getCustomerCountry(state),
  )
  const taxNumber = useOptIn(state =>
    getFieldValueBySlug(state, FieldSlugEnum.TaxNumber),
  )
  const isVatNotChargeable = usePayment(isOnlyPersonalAvailable)

  const isTwoStepOptInStepVisible =
    !!twoStepPaymentFormStepType.length &&
    paymentStep === PaymentFormStepTypeEnum.STEP_OPT_IN

  const productVat = getVat(
    offerOwnerCountry,
    customerCountry,
    taxNumber,
    product,
  )

  useEffect(() => {
    if (
      mercadoPago &&
      (pricePlan || product?.id) &&
      !isTwoStepOptInStepVisible
    ) {
      const cf = product?.id
        ? initMercadoPagoCardForm(
            mercadoPago,
            getPriceWithCoupon(
              selectedProductPrice,
              coupon,
              productQuantity,
              isVatNotChargeable ? 0 : productVat,
            ),
          )
        : initMercadoPagoCardForm(
            mercadoPago,
            getPriceWithCoupon(pricePlanAmount, coupon),
          )
      setCardForm(cf)

      return () => {
        cf.unmount()
      }
    }
  }, [
    mercadoPago,
    pricePlanAmount,
    selectedProductPrice,
    productQuantity,
    isTwoStepOptInStepVisible,
  ])

  useEffect(() => {
    if (
      mercadoCreditCardTypes.some(paymentMethod =>
        paymentMethods.includes(paymentMethod),
      ) &&
      mercadoPagoPublicKey &&
      !isTwoStepOptInStepVisible
    ) {
      loadScript(
        process.env.MERCADO_PAGO_API_URL as string,
        'mercado-pago',
        async () => {
          const mp = new window.MercadoPago(mercadoPagoPublicKey)
          setMercadoPago(mp)
        },
      )
    }
  }, [coupon, isTwoStepOptInStepVisible])

  function addError(key: string, message: string) {
    setErrors(errors => ({
      ...errors,
      [key]: message,
    }))
  }

  function initMercadoPagoCardForm(
    mercadoPago: MercadoPago,
    pricePlanAmount: number,
  ) {
    return mercadoPago.cardForm({
      amount: `${pricePlanAmount}`,
      form: formOptions,
      callbacks: {
        onFormMounted: (error: any) => {
          if (error && typeof window.Rollbar !== 'undefined') {
            window.Rollbar.error(`Mercadopago payment mount failed`, error)
          }
        },
        onValidityChange: (errors?: MercadoPageFormError[], field?: string) => {
          if (errors && errors.length > 0 && field) {
            errors.forEach(error => {
              const fieldError = getFieldError(error.code)
              addError(fieldError.field, t(fieldError.message))
            })
          } else if (field) {
            resetError(field)
          }
        },
        onCardTokenReceived: (
          errors?: MercadoPagoServerError | MercadoPageFormError[] | string,
        ) => {
          if (typeof errors === 'string') {
            if (typeof window.Rollbar !== 'undefined') {
              window.Rollbar.error(
                `onCardTokenReceived: ${JSON.stringify(errors)}`,
              )
            } else {
              console.log('onCardTokenReceived errors: ', errors)
            }
          } else if (
            typeof errors === 'object' &&
            (errors as MercadoPagoServerError).cause
          ) {
            ;(errors as MercadoPagoServerError).cause.forEach(cause => {
              const { field, message } = getFieldError(cause.code)
              addError(field, t(message))
            })
          } else if (Array.isArray(errors)) {
            Object.values(errors).forEach(error => {
              const fieldError = getFieldError(error.code)
              addError(fieldError.field, fieldError.message)
            })
          }
        },
        onSubmit: async (event: any) => {
          event.preventDefault()
        },
      },
    }) as CardForm
  }

  const createCardToken = useCallback(async () => {
    if (cardForm) {
      let cardFormData = await getCardFormData(0, cardForm)

      if (!cardFormData.paymentMethodId) {
        if (window.Rollbar) {
          window.Rollbar.info(
            'MercadoPago cardFormData without paymentMethodId first try',
            { cardFormData },
          )
        }
        cardFormData = await getCardFormData(2000, cardForm)
      }

      if (!cardFormData.paymentMethodId) {
        if (window.Rollbar) {
          window.Rollbar.info(
            'MercadoPago cardFormData without paymentMethodId second try',
            { cardFormData },
          )
        }
        return
      }

      setErrors({})
      return {
        token: cardFormData.token,
        paymentMethodId: cardFormData.paymentMethodId,
        installments: cardFormData.installments,
      }
    }
  }, [cardForm])

  function resetError(key: string) {
    setErrors(errors => ({
      ...errors,
      [key]: '',
    }))
  }

  return (
    <MercadoPagoContext.Provider
      value={{
        cardForm,
        errors,
        resetError,
        setErrors,
        createCardToken,
        areInstallmentsVisible: pricePlan
          ? pricePlan.type === PricePlanTypeEnum.OneShot
          : !!product?.id,
      }}
    >
      <form id="form-checkout">{props.children}</form>
    </MercadoPagoContext.Provider>
  )
}
