import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { changeField } from 'publisher/actions/optInActions'
import { useMercadoPago } from 'publisher/context/mercadoPagoContext'
import { useOptIn } from 'publisher/store'
import {
  getFieldErrorsBySlug,
  getFieldValueBySlug,
} from 'publisher/store/optIn/optInSelectors'
import {
  customTaxNumberOptions,
  CustomTaxNumberOptionsKeys,
} from '../constants/custom-tax-number-options'

export function useCustomTaxNumber(type: CustomTaxNumberOptionsKeys) {
  const dispatch = useDispatch()
  const { errors } = useMercadoPago()
  const value = useOptIn(optIn =>
    getFieldValueBySlug(optIn, FieldSlugEnum.TaxNumber),
  )
  const optInErrors = useOptIn(optIn =>
    getFieldErrorsBySlug(optIn, FieldSlugEnum.TaxNumber),
  )
  const [error, setError] = useState('')
  const [identificationType, setIdentificationType] = useState(
    customTaxNumberOptions[type].personalIdentificationType,
  )

  useEffect(() => {
    if (value) {
      setIdentificationType(
        customTaxNumberOptions[type].detectIdentificationType(value),
      )
    }
  }, [value])

  useEffect(() => {
    if (errors.identificationNumber) {
      setError(errors.identificationNumber)
    }
  }, [errors])

  function resetError() {
    setError('')
  }

  function change(value: string) {
    setIdentificationType(
      customTaxNumberOptions[type].detectIdentificationType(value),
    )

    dispatch(changeField(FieldSlugEnum.TaxNumber, value))
    if (error) {
      resetError()
    }
  }

  return {
    change,
    value,
    errors: optInErrors.length > 0 ? optInErrors : error ? [error] : [],
    identificationType,
  }
}
