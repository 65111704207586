export const paymentMethodTypes = {
  STRIPE_CARD: 'stripe:card',
  STRIPE_IDEAL: 'stripe:ideal',
  STRIPE_SEPA_DEBIT: 'stripe:sepa_debit',
  STRIPE_BANCONTACT: 'stripe:bancontact',
  PAYPAL_SOAP: 'paypal-soap',
  DLOCAL_CARD: 'dlocal:card',
  DLOCAL_BOLETO: 'dlocal:boleto',
  DLOCAL_PIX: 'dlocal:pix',
  MERCADO_PAGO_CARD: 'mercadopago:card',
  MERCADO_PAGO_PIX: 'mercadopago:pix',
  MERCADO_PAGO_BOLETO: 'mercadopago:boleto',
  MERCADO_PAGO_BR_CARD: 'mercadopago-br:card',
  MERCADO_PAGO_BR_PIX: 'mercadopago-br:pix',
  MERCADO_PAGO_BR_BOLETO: 'mercadopago-br:boleto',
  MERCADO_PAGO_CO_CARD: 'mercadopago-co:card',
  MERCADO_PAGO_MX_CARD: 'mercadopago-mx:card',
  MERCADO_PAGO_AR_CARD: 'mercadopago-ar:card',
  MERCADO_PAGO_CL_CARD: 'mercadopago-cl:card',
  RAZORPAY: 'razorpay',
  FLUTTERWAVE: 'flutterwave',
  MOLLIE: 'mollie',
  GOCARDLESS: 'gocardless',
  CASH_ON_DELIVERY: 'cash-on-delivery',
  XENDITIDCARD: 'xendit-id:card',
  XENDITPHCARD: 'xendit-ph:card',
  // XENDITIDDD: 'xendit-id:dd',
  // XENDITIDEWALLET: 'xendit-id:ewallet',
  // XENDITPHDD: 'xendit-ph:dd',
  // XENDITPHEWALLET: 'xendit-ph:ewallet',
  PAYSTACK_GH_CARD: 'paystack-gh:card',
  PAYSTACK_KE_CARD: 'paystack-ke:card',
  PAYSTACK_NG_CARD: 'paystack-ng:card',
  PAYSTACK_ZA_CARD: 'paystack-za:card',
}
// mercadopago-br:card","mercadopago-br:pix","mercadopago-br:boleto
export default paymentMethodTypes

export const paymentMethodsNames = {
  [paymentMethodTypes.STRIPE_CARD]: 'Credit Cards',
  [paymentMethodTypes.STRIPE_IDEAL]: 'iDeal',
  [paymentMethodTypes.PAYPAL_SOAP]: 'PayPal',
  [paymentMethodTypes.STRIPE_BANCONTACT]: 'Bancontact',
  [paymentMethodTypes.DLOCAL_CARD]: 'Credit or debit card (Dlocal)',
  [paymentMethodTypes.DLOCAL_BOLETO]: 'Boleto (Dlocal)',
  [paymentMethodTypes.DLOCAL_PIX]: 'Pix (Dlocal)',
  [paymentMethodTypes.MERCADO_PAGO_CARD]: 'Credit or debit card (Mercadopago)',
  [paymentMethodTypes.MERCADO_PAGO_PIX]: 'Pix (Mercadopago)',
  [paymentMethodTypes.MERCADO_PAGO_BR_PIX]: 'Pix (Mercadopago)',
  [paymentMethodTypes.MERCADO_PAGO_BOLETO]: 'Boleto (Mercadopago)',
  [paymentMethodTypes.MERCADO_PAGO_BR_BOLETO]: 'Boleto (Mercadopago)',
  [paymentMethodTypes.MERCADO_PAGO_BR_CARD]:
    'Credit or debit card (Mercadopago)',
  [paymentMethodTypes.MERCADO_PAGO_CO_CARD]:
    'Credit or debit card (Mercadopago)',
  [paymentMethodTypes.MERCADO_PAGO_MX_CARD]:
    'Credit or debit card (Mercadopago)',
  [paymentMethodTypes.MERCADO_PAGO_AR_CARD]:
    'Credit or debit card (Mercadopago)',
  [paymentMethodTypes.RAZORPAY]: 'Razorpay',
  [paymentMethodTypes.FLUTTERWAVE]: 'Flutterwave',
  [paymentMethodTypes.MOLLIE]: 'Mollie',
  [paymentMethodTypes.GOCARDLESS]: 'Direct Debit',
  [paymentMethodTypes.CASH_ON_DELIVERY]: 'Cash on delivery',
  [paymentMethodTypes.XENDITIDCARD]: 'Xendit',
  [paymentMethodTypes.XENDITPHCARD]: 'Xendit',
  [paymentMethodTypes.PAYSTACK_GH_CARD]: 'Credit or debit card (Paystack)',
  [paymentMethodTypes.PAYSTACK_KE_CARD]: 'Credit or debit card (Paystack)',
  [paymentMethodTypes.PAYSTACK_NG_CARD]: 'Credit or debit card (Paystack)',
  [paymentMethodTypes.PAYSTACK_ZA_CARD]: 'Credit or debit card (Paystack)',
}

export const paymentMethodsImages = {
  [paymentMethodTypes.STRIPE_CARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.STRIPE_IDEAL]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/ideal.png`,
  [paymentMethodTypes.PAYPAL_SOAP]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/paypal.png`,
  [paymentMethodTypes.STRIPE_BANCONTACT]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/bancontact.png`,
  [paymentMethodTypes.DLOCAL_CARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.DLOCAL_BOLETO]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/boleto.png`,
  [paymentMethodTypes.MERCADO_PAGO_BOLETO]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/boleto.png`,
  [paymentMethodTypes.DLOCAL_PIX]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/pix.png`,
  [paymentMethodTypes.MERCADO_PAGO_PIX]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/pix.png`,
  [paymentMethodTypes.MERCADO_PAGO_CARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.MERCADO_PAGO_BR_PIX]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/pix.png`,
  [paymentMethodTypes.MERCADO_PAGO_BR_BOLETO]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/boleto.png`,
  [paymentMethodTypes.MERCADO_PAGO_BR_CARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.MERCADO_PAGO_CO_CARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.MERCADO_PAGO_MX_CARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.MERCADO_PAGO_AR_CARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.MERCADO_PAGO_CL_CARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.RAZORPAY]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/razorpay.svg`,
  [paymentMethodTypes.FLUTTERWAVE]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/flutterwave.svg`,
  [paymentMethodTypes.MOLLIE]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/mollie.png`,
  [paymentMethodTypes.GOCARDLESS]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/gocardless.svg`,
  [paymentMethodTypes.XENDITIDCARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.XENDITPHCARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.PAYSTACK_GH_CARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.PAYSTACK_KE_CARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.PAYSTACK_NG_CARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
  [paymentMethodTypes.PAYSTACK_ZA_CARD]: `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/credit_cards.png`,
}

export const paymentMethodsImagesSize = {
  [paymentMethodTypes.STRIPE_IDEAL]: { width: '41', height: '36' },
  [paymentMethodTypes.PAYPAL_SOAP]: { width: '115', height: '28' },
  [paymentMethodTypes.STRIPE_BANCONTACT]: { width: '63', height: '39' },
  [paymentMethodTypes.DLOCAL_CARD]: { width: '223', height: '36' },
  [paymentMethodTypes.DLOCAL_BOLETO]: { width: '63', height: '40' },
  [paymentMethodTypes.MERCADO_PAGO_BOLETO]: { width: '63', height: '40' },
  [paymentMethodTypes.DLOCAL_PIX]: { width: '80', height: '28' },
  [paymentMethodTypes.MERCADO_PAGO_PIX]: { width: '80', height: '28' },
  [paymentMethodTypes.MERCADO_PAGO_CARD]: { width: '223', height: '36' },
  [paymentMethodTypes.MERCADO_PAGO_BR_PIX]: { width: '80', height: '28' },
  [paymentMethodTypes.MERCADO_PAGO_BR_BOLETO]: { width: '63', height: '40' },
  [paymentMethodTypes.MERCADO_PAGO_BR_CARD]: { width: '223', height: '36' },
  [paymentMethodTypes.MERCADO_PAGO_CO_CARD]: { width: '223', height: '36' },
  [paymentMethodTypes.MERCADO_PAGO_MX_CARD]: { width: '223', height: '36' },
  [paymentMethodTypes.MERCADO_PAGO_AR_CARD]: { width: '223', height: '36' },
  [paymentMethodTypes.MERCADO_PAGO_CL_CARD]: { width: '223', height: '36' },
  [paymentMethodTypes.RAZORPAY]: { width: '170', height: '36' },
  [paymentMethodTypes.FLUTTERWAVE]: { width: '185', height: '36' },
  [paymentMethodTypes.MOLLIE]: { width: '238', height: '60' },
  [paymentMethodTypes.GOCARDLESS]: { width: '115', height: '28' },
  [paymentMethodTypes.XENDITIDCARD]: { width: '223', height: '36' },
  [paymentMethodTypes.XENDITPHCARD]: { width: '223', height: '36' },
  [paymentMethodTypes.PAYSTACK_GH_CARD]: { width: '223', height: '36' },
  [paymentMethodTypes.PAYSTACK_KE_CARD]: { width: '223', height: '36' },
  [paymentMethodTypes.PAYSTACK_NG_CARD]: { width: '223', height: '36' },
  [paymentMethodTypes.PAYSTACK_ZA_CARD]: { width: '223', height: '36' },
}
