import { shallowEqual, TypedUseSelectorHook, useSelector } from 'react-redux'
import { CustomerTypeEnum } from 'common/enums/CustomerTypeEnum'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import { PaymentFormStepTypeEnum } from 'common/enums/PaymentFormStepTypeEnum'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { Coupon } from 'common/types/CouponType'
import {
  EntitiesStructure,
  GlobalSettingsInterface,
  SeoInterface,
} from 'common/types/Page'
import { ProductVariantInterface } from 'common/types/entities/ProductInterface'
import { selectors as optInSelectors } from 'publisher/reducers/optInReducer'
import { selectors as pageSelectors } from 'publisher/reducers/pageReducer'
import filesSelectors from 'publisher/store/files/filesSelectors'
import managementSelectors from 'publisher/store/management/managementSelectors'
import { ManagementState } from 'publisher/store/management/managementStateInterface'
import { OptInState } from 'publisher/store/optIn/optInStateInterface'
import typedPageSelectors from 'publisher/store/page/pageSelectors'

export interface PageState {
  type: PageTypeEnum
  locale: PageLocaleEnum
  id: number
  entities: EntitiesStructure
  globalSettings: GlobalSettingsInterface
  seo: SeoInterface
  isTemplate: boolean
}

interface DataFile {
  id: number
  path: string
  filename: string
}

export type FilesState = Record<number, DataFile>

export interface PaymentState {
  dlocalDocumentNumber?: string
  mercadoPagoPublicKey?: string
  purchaseProcessId: number
  paymentMethods: PaymentMethodEnum
  checkedPlanId: number
  offer: string
  physicalProduct: string
  checkedBumpsIds: number[] // deprecated
  checkedBumpId: number
  checkedProductAttributesOptions: Record<number, number>
  quantity: number
  customerPaymentMethod?: PaymentMethodEnum
  customerType: string | CustomerTypeEnum
  checkedCoupon?: Coupon
  offerBumps: string
  offerBump: string
  offerOwnerCountry: string
  isOnlyPersonalAvailable: boolean
  product: string
  productQuantity: number
  deadlineTime: string
  deadlineExpirationUrl: string
  productActiveVariant: null | ProductVariantInterface
  twoStepPaymentFormStepType: PaymentFormStepTypeEnum
  cashOnDeliveryInstruction: string
  xenditPublicKey?: string
}

interface RootState {
  page: PageState
  optIn: OptInState
  payment: PaymentState
  offerThankYou: offerThankYouState
  management: ManagementState
  files: FilesState
  webinar: WebinarState
}

export interface offerThankYouState {
  orderSummary: string
}

export interface WebinarState {
  timeStamp: string
  url: string
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

export function usePage<T>(selector: (pageState: PageState) => T): T {
  return useTypedSelector(state => selector(state.page), shallowEqual)
}

export function useOptIn<T>(selector: (optInState: OptInState) => T): T {
  return useTypedSelector(state => selector(state.optIn), shallowEqual)
}

export function usePayment<T>(selector: (paymentState: PaymentState) => T): T {
  return useTypedSelector(state => selector(state.payment), shallowEqual)
}

export function useManagement<T>(
  selector: (paymentState: ManagementState) => T,
): T {
  return useTypedSelector(state => selector(state.management), shallowEqual)
}

export function useFiles<T>(selector: (paymentState: FilesState) => T): T {
  return useTypedSelector(state => selector(state.files), shallowEqual)
}

export function useOfferThankYou<T>(
  selector: (offerThankYouState: offerThankYouState) => T,
): T {
  return useTypedSelector(state => selector(state.offerThankYou), shallowEqual)
}

export function normalizeStringBoolean(stringBoolean: string) {
  return stringBoolean === 'true' || stringBoolean === '1'
}

export function useWebinar<T>(selector: (webinarState: WebinarState) => T): T {
  return useTypedSelector(state => selector(state.webinar), shallowEqual)
}

export {
  pageSelectors,
  optInSelectors,
  typedPageSelectors,
  managementSelectors,
  filesSelectors,
}
