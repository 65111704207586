import React from 'react'
import { useTranslation } from 'react-i18next'
import { Coupon as CouponType } from 'common/types/CouponType'
import {
  OneShotPlan,
  PricePlan as PricePlanType,
} from '../../../types/OfferInterface'
import {
  getNetPrice,
  getPricePlanAmount,
  getReadablePrice,
  centsToEuro,
} from '../../../utils/priceCalculator'
import Coupon from './Coupon'
import PricePlanAmountUi from './ui/PricePlanAmountUi'
import PricePlanDescriptionUi from './ui/PricePlanDescriptionUi'
import PricePlanItemUi from './ui/PricePlanItemUi'
import PricePlanUi from './ui/PricePlanUi'

type OneShotsSummaryProps = {
  plan: PricePlanType
  bumpPlan?: OneShotPlan
  quantity: number
  tax: number
  bumpTax: number
  isVatNotChargeable: boolean
  coupon?: CouponType
  locale: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
}

function OneShotsSummary({
  plan,
  bumpPlan,
  tax,
  bumpTax,
  quantity,
  isVatNotChargeable,
  coupon,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
}: OneShotsSummaryProps) {
  const { t } = useTranslation(['common', 'publisher'])
  const currency = plan.currency
  const planAmount = getPricePlanAmount(plan)
  const planNetPrice = getNetPrice(planAmount, quantity, coupon)
  let totalAmountWithTax = planNetPrice + tax
  let totalAmountWithoutTaxAndDiscount = planAmount * quantity
  if (bumpPlan) {
    totalAmountWithTax += bumpPlan.directChargeAmount + bumpTax
    totalAmountWithoutTaxAndDiscount += bumpPlan.directChargeAmount
  }
  const readableTotalAmountWithTax = getReadablePrice(
    centsToEuro(totalAmountWithTax),
    currency,
    locale,
  )
  const readableTotalAmountWithoutTaxAndDiscount = getReadablePrice(
    centsToEuro(totalAmountWithoutTaxAndDiscount),
    currency,
    locale,
  )
  const totalTax = tax * quantity + bumpTax
  const readableTotalTax = getReadablePrice(
    centsToEuro(totalTax),
    currency,
    locale,
  )
  return (
    <PricePlanUi>
      <PricePlanItemUi>
        <PricePlanDescriptionUi
          fontSize={descriptionFontSize}
          mobileFontSize={mobileDescriptionFontSize}
          fontStyle={descriptionFontStyle}
          mobileFontStyle={mobileDescriptionFontStyle}
          fontWeight={descriptionFontWeight}
          mobileFontWeight={mobileDescriptionFontWeight}
          fontFamily={descriptionFontFamily}
          mobileFontFamily={mobileDescriptionFontFamily}
          lineHeight={descriptionLineHeight}
          mobileLineHeight={mobileDescriptionLineHeight}
          color={descriptionColor}
          data-test-id={`offer-price-one-shot-${plan.id}`}
        >
          {/*
      since there are no taxes it could be confusing for customers
      they could wonder if they will end up paying VAT on top of that
      several users raised this issue
      and I think it makes sense
      so if users selected "Not charge VAT on their payment pages"
      then I propose we display:
      Total T.T.C.
      */}
          {t(
            isVatNotChargeable
              ? 'components.offer_price.one_shots_summary.total_amount_with_tax' // @see why https://systeme-team.slack.com/archives/G61JS7GDC/p1566808055000200
              : 'components.offer_price.one_shots_summary.total_amount',
          )}
        </PricePlanDescriptionUi>
        <PricePlanAmountUi
          fontSize={amountFontSize}
          mobileFontSize={mobileAmountFontSize}
          fontStyle={amountFontStyle}
          mobileFontStyle={mobileAmountFontStyle}
          fontWeight={amountFontWeight}
          mobileFontWeight={mobileAmountFontWeight}
          fontFamily={amountFontFamily}
          mobileFontFamily={mobileAmountFontFamily}
          lineHeight={amountLineHeight}
          mobileLineHeight={mobileAmountLineHeight}
          color={amountColor}
        >
          {readableTotalAmountWithoutTaxAndDiscount}
        </PricePlanAmountUi>
      </PricePlanItemUi>
      {coupon && (
        <Coupon
          coupon={coupon}
          locale={locale}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          descriptionFontSize={descriptionFontSize}
          mobileDescriptionFontSize={mobileDescriptionFontSize}
          descriptionFontStyle={descriptionFontStyle}
          mobileDescriptionFontStyle={mobileDescriptionFontStyle}
          descriptionFontWeight={descriptionFontWeight}
          mobileDescriptionFontWeight={mobileDescriptionFontWeight}
          descriptionFontFamily={descriptionFontFamily}
          mobileDescriptionFontFamily={mobileDescriptionFontFamily}
          descriptionLineHeight={descriptionLineHeight}
          mobileDescriptionLineHeight={mobileDescriptionLineHeight}
          descriptionColor={descriptionColor}
        />
      )}
      {totalTax > 0 && (
        <React.Fragment>
          <PricePlanItemUi>
            <PricePlanDescriptionUi
              fontSize={descriptionFontSize}
              mobileFontSize={mobileDescriptionFontSize}
              fontStyle={descriptionFontStyle}
              mobileFontStyle={mobileDescriptionFontStyle}
              fontWeight={descriptionFontWeight}
              mobileFontWeight={mobileDescriptionFontWeight}
              fontFamily={descriptionFontFamily}
              mobileFontFamily={mobileDescriptionFontFamily}
              lineHeight={descriptionLineHeight}
              mobileLineHeight={mobileDescriptionLineHeight}
              color={descriptionColor}
              data-test-id={`offer-price-one-shot-tax-${plan.id}`}
            >
              {t('components.offer_price.one_shots_summary.total_tax')}
            </PricePlanDescriptionUi>
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
            >
              {readableTotalTax}
            </PricePlanAmountUi>
          </PricePlanItemUi>
          <PricePlanItemUi>
            <PricePlanDescriptionUi
              fontSize={descriptionFontSize}
              mobileFontSize={mobileDescriptionFontSize}
              fontStyle={descriptionFontStyle}
              mobileFontStyle={mobileDescriptionFontStyle}
              fontWeight={descriptionFontWeight}
              mobileFontWeight={mobileDescriptionFontWeight}
              fontFamily={descriptionFontFamily}
              mobileFontFamily={mobileDescriptionFontFamily}
              lineHeight={descriptionLineHeight}
              mobileLineHeight={mobileDescriptionLineHeight}
              color={descriptionColor}
              data-test-id={`offer-price-one-shot-total-amount-with-tax-${plan.id}`}
            >
              {t(
                'components.offer_price.one_shots_summary.total_amount_with_tax',
              )}
            </PricePlanDescriptionUi>
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
            >
              {readableTotalAmountWithTax}
            </PricePlanAmountUi>
          </PricePlanItemUi>
        </React.Fragment>
      )}
      {coupon && !totalTax && (
        <>
          <PricePlanDescriptionUi
            fontSize={descriptionFontSize}
            mobileFontSize={mobileDescriptionFontSize}
            fontStyle={descriptionFontStyle}
            mobileFontStyle={mobileDescriptionFontStyle}
            fontWeight={descriptionFontWeight}
            mobileFontWeight={mobileDescriptionFontWeight}
            fontFamily={descriptionFontFamily}
            mobileFontFamily={mobileDescriptionFontFamily}
            lineHeight={descriptionLineHeight}
            mobileLineHeight={mobileDescriptionLineHeight}
            color={descriptionColor}
            data-test-id={`offer-price-one-shot-total-amount-with-coupon-${plan.id}`}
          >
            {t(
              'components.offer_price.one_shots_summary.total_amount_with_coupon',
            )}
          </PricePlanDescriptionUi>
          <PricePlanAmountUi
            fontSize={amountFontSize}
            mobileFontSize={mobileAmountFontSize}
            fontStyle={amountFontStyle}
            mobileFontStyle={mobileAmountFontStyle}
            fontWeight={amountFontWeight}
            mobileFontWeight={mobileAmountFontWeight}
            fontFamily={amountFontFamily}
            mobileFontFamily={mobileAmountFontFamily}
            lineHeight={amountLineHeight}
            mobileLineHeight={mobileAmountLineHeight}
            color={amountColor}
          >
            {readableTotalAmountWithTax}
          </PricePlanAmountUi>
        </>
      )}
    </PricePlanUi>
  )
}

export default OneShotsSummary
