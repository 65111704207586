import React from 'react'
import { PricePlanTypeEnum } from 'common/enums/PricePlanTypeEnum'
import { Coupon as CouponType } from 'common/types/CouponType'
import {
  OneShotPlan,
  PricePlan as PricePlanType,
} from 'common/types/OfferInterface'
import {
  ProductBumpInfoInterface,
  ProductInfoInterface,
} from 'common/types/entities/ProductInterface'
import OneShotsSummary from './OneShotsSummary'
import PricePlan from './PricePlan'
import BumpPricePlan from './PricePlan/BumpPricePlan'
import Product from './Product'
import ProductAndPricePlanBumpTotal from './Product/ProductAndPricePlanBumpTotal'
import ProductBump from './Product/ProductBump'
import ProductBumpTotal from './Product/ProductBumpTotal'
import DividerUi from './ui/DividerUi'
import PricePlanListUi from './ui/PricePlanListUi'

export type OfferPriceProps = {
  attrId: string
  activePlanId?: number
  plans: PricePlanType[]
  tax: number
  bumpPlan?: OneShotPlan
  bumpTax?: number
  coupon?: CouponType
  quantity?: number
  isVatNotChargeable: boolean
  togglePricePlan?: (id: number) => void
  customDescription?: string
  locale: string
  productInfo?: ProductInfoInterface
  productBumpInfo?: ProductBumpInfoInterface
  nameFontFamily?: string
  mobileNameFontFamily?: string
  nameFontSize: number
  mobileNameFontSize?: number
  nameFontStyle?: string
  mobileNameFontStyle?: string
  nameFontWeight?: string
  mobileNameFontWeight?: string
  nameLineHeight: number
  mobileNameLineHeight?: number
  nameColor: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
}

export function isOneShot(plan: PricePlanType) {
  return plan.type === PricePlanTypeEnum.OneShot
}

function OfferPrice({
  attrId,
  plans,
  coupon,
  bumpPlan,
  tax,
  bumpTax = 0,
  quantity = 1,
  activePlanId,
  togglePricePlan,
  isVatNotChargeable,
  customDescription,
  locale,
  productInfo,
  productBumpInfo,
  nameFontSize,
  mobileNameFontSize,
  nameFontStyle,
  mobileNameFontStyle,
  nameFontWeight,
  mobileNameFontWeight,
  nameFontFamily,
  mobileNameFontFamily,
  nameLineHeight,
  mobileNameLineHeight,
  nameColor,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
}: OfferPriceProps) {
  const activePricePlan = plans.find(
    plan => plan.id === activePlanId,
  ) as PricePlanType

  const needOneShotsSummary =
    bumpPlan &&
    isOneShot(bumpPlan) &&
    activePricePlan &&
    isOneShot(activePricePlan)

  const isCombinationOfProductAndPricePlanBump =
    productInfo && productInfo.name && bumpPlan && isOneShot(bumpPlan)

  return (
    <div id={attrId} data-test-element="offer-price">
      <PricePlanListUi>
        {plans.map(plan => (
          <PricePlan
            key={plan.id}
            tax={tax}
            plan={plan}
            quantity={quantity}
            coupon={coupon}
            isActive={plan.id === activePlanId}
            togglePricePlan={togglePricePlan}
            customDescription={customDescription}
            locale={locale}
            nameFontSize={nameFontSize}
            mobileNameFontSize={mobileNameFontSize}
            nameFontStyle={nameFontStyle}
            mobileNameFontStyle={mobileNameFontStyle}
            nameFontWeight={nameFontWeight}
            mobileNameFontWeight={mobileNameFontWeight}
            nameFontFamily={nameFontFamily}
            mobileNameFontFamily={mobileNameFontFamily}
            nameLineHeight={nameLineHeight}
            mobileNameLineHeight={mobileNameLineHeight}
            nameColor={nameColor}
            amountFontSize={amountFontSize}
            mobileAmountFontSize={mobileAmountFontSize}
            amountFontStyle={amountFontStyle}
            mobileAmountFontStyle={mobileAmountFontStyle}
            amountFontWeight={amountFontWeight}
            mobileAmountFontWeight={mobileAmountFontWeight}
            amountFontFamily={amountFontFamily}
            mobileAmountFontFamily={mobileAmountFontFamily}
            amountLineHeight={amountLineHeight}
            mobileAmountLineHeight={mobileAmountLineHeight}
            amountColor={amountColor}
            descriptionFontSize={descriptionFontSize}
            mobileDescriptionFontSize={mobileDescriptionFontSize}
            descriptionFontStyle={descriptionFontStyle}
            mobileDescriptionFontStyle={mobileDescriptionFontStyle}
            descriptionFontWeight={descriptionFontWeight}
            mobileDescriptionFontWeight={mobileDescriptionFontWeight}
            descriptionFontFamily={descriptionFontFamily}
            mobileDescriptionFontFamily={mobileDescriptionFontFamily}
            descriptionLineHeight={descriptionLineHeight}
            mobileDescriptionLineHeight={mobileDescriptionLineHeight}
            descriptionColor={descriptionColor}
            dataTestId={`offer-price-${plan.id}`}
          />
        ))}
      </PricePlanListUi>
      <Product
        productInfo={productInfo}
        coupon={coupon}
        nameFontSize={nameFontSize}
        mobileNameFontSize={mobileNameFontSize}
        nameFontStyle={nameFontStyle}
        mobileNameFontStyle={mobileNameFontStyle}
        nameFontWeight={nameFontWeight}
        mobileNameFontWeight={mobileNameFontWeight}
        nameFontFamily={nameFontFamily}
        mobileNameFontFamily={mobileNameFontFamily}
        nameLineHeight={nameLineHeight}
        mobileNameLineHeight={mobileNameLineHeight}
        nameColor={nameColor}
        amountFontSize={amountFontSize}
        mobileAmountFontSize={mobileAmountFontSize}
        amountFontStyle={amountFontStyle}
        mobileAmountFontStyle={mobileAmountFontStyle}
        amountFontWeight={amountFontWeight}
        mobileAmountFontWeight={mobileAmountFontWeight}
        amountFontFamily={amountFontFamily}
        mobileAmountFontFamily={mobileAmountFontFamily}
        amountLineHeight={amountLineHeight}
        mobileAmountLineHeight={mobileAmountLineHeight}
        amountColor={amountColor}
        descriptionFontSize={descriptionFontSize}
        mobileDescriptionFontSize={mobileDescriptionFontSize}
        descriptionFontStyle={descriptionFontStyle}
        mobileDescriptionFontStyle={mobileDescriptionFontStyle}
        descriptionFontWeight={descriptionFontWeight}
        mobileDescriptionFontWeight={mobileDescriptionFontWeight}
        descriptionFontFamily={descriptionFontFamily}
        mobileDescriptionFontFamily={mobileDescriptionFontFamily}
        descriptionLineHeight={descriptionLineHeight}
        mobileDescriptionLineHeight={mobileDescriptionLineHeight}
        descriptionColor={descriptionColor}
      />
      {productBumpInfo && productBumpInfo.name && (
        <ProductBump
          skipLeftOffset={!activePricePlan}
          productBumpInfo={productBumpInfo}
          nameFontSize={nameFontSize}
          mobileNameFontSize={mobileNameFontSize}
          nameFontStyle={nameFontStyle}
          mobileNameFontStyle={mobileNameFontStyle}
          nameFontWeight={nameFontWeight}
          mobileNameFontWeight={mobileNameFontWeight}
          nameFontFamily={nameFontFamily}
          mobileNameFontFamily={mobileNameFontFamily}
          nameLineHeight={nameLineHeight}
          mobileNameLineHeight={mobileNameLineHeight}
          nameColor={nameColor}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
        />
      )}
      {productBumpInfo && productBumpInfo.name && (
        <ProductBumpTotal
          productBumpInfo={productBumpInfo}
          activePricePlan={activePricePlan}
          productInfo={productInfo}
          quantity={quantity}
          tax={tax}
          coupon={coupon}
          locale={locale}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          descriptionFontSize={descriptionFontSize}
          mobileDescriptionFontSize={mobileDescriptionFontSize}
          descriptionFontStyle={descriptionFontStyle}
          mobileDescriptionFontStyle={mobileDescriptionFontStyle}
          descriptionFontWeight={descriptionFontWeight}
          mobileDescriptionFontWeight={mobileDescriptionFontWeight}
          descriptionFontFamily={descriptionFontFamily}
          mobileDescriptionFontFamily={mobileDescriptionFontFamily}
          descriptionLineHeight={descriptionLineHeight}
          mobileDescriptionLineHeight={mobileDescriptionLineHeight}
          descriptionColor={descriptionColor}
        />
      )}
      {bumpPlan && (
        <>
          <DividerUi />
          <BumpPricePlan
            nameFontSize={nameFontSize}
            mobileNameFontSize={mobileNameFontSize}
            nameFontStyle={nameFontStyle}
            mobileNameFontStyle={mobileNameFontStyle}
            nameFontWeight={nameFontWeight}
            mobileNameFontWeight={mobileNameFontWeight}
            nameFontFamily={nameFontFamily}
            mobileNameFontFamily={mobileNameFontFamily}
            nameLineHeight={nameLineHeight}
            mobileNameLineHeight={mobileNameLineHeight}
            nameColor={nameColor}
            amountFontSize={amountFontSize}
            mobileAmountFontSize={mobileAmountFontSize}
            amountFontStyle={amountFontStyle}
            mobileAmountFontStyle={mobileAmountFontStyle}
            amountFontWeight={amountFontWeight}
            mobileAmountFontWeight={mobileAmountFontWeight}
            amountFontFamily={amountFontFamily}
            mobileAmountFontFamily={mobileAmountFontFamily}
            amountLineHeight={amountLineHeight}
            mobileAmountLineHeight={mobileAmountLineHeight}
            amountColor={amountColor}
            descriptionFontSize={descriptionFontSize}
            mobileDescriptionFontSize={mobileDescriptionFontSize}
            descriptionFontStyle={descriptionFontStyle}
            mobileDescriptionFontStyle={mobileDescriptionFontStyle}
            descriptionFontWeight={descriptionFontWeight}
            mobileDescriptionFontWeight={mobileDescriptionFontWeight}
            descriptionFontFamily={descriptionFontFamily}
            mobileDescriptionFontFamily={mobileDescriptionFontFamily}
            descriptionLineHeight={descriptionLineHeight}
            mobileDescriptionLineHeight={mobileDescriptionLineHeight}
            descriptionColor={descriptionColor}
            plan={bumpPlan}
            dataTestId={`offer-price-bump-${bumpPlan.id}`}
            tax={bumpTax}
            locale={locale}
          />
        </>
      )}
      {bumpPlan && isCombinationOfProductAndPricePlanBump && (
        <ProductAndPricePlanBumpTotal
          productInfo={productInfo}
          bumpPlan={bumpPlan}
          bumpTax={bumpTax}
          amountFontSize={amountFontSize}
          mobileAmountFontSize={mobileAmountFontSize}
          amountFontStyle={amountFontStyle}
          mobileAmountFontStyle={mobileAmountFontStyle}
          amountFontWeight={amountFontWeight}
          mobileAmountFontWeight={mobileAmountFontWeight}
          amountFontFamily={amountFontFamily}
          mobileAmountFontFamily={mobileAmountFontFamily}
          amountLineHeight={amountLineHeight}
          mobileAmountLineHeight={mobileAmountLineHeight}
          amountColor={amountColor}
          descriptionFontSize={descriptionFontSize}
          mobileDescriptionFontSize={mobileDescriptionFontSize}
          descriptionFontStyle={descriptionFontStyle}
          mobileDescriptionFontStyle={mobileDescriptionFontStyle}
          descriptionFontWeight={descriptionFontWeight}
          mobileDescriptionFontWeight={mobileDescriptionFontWeight}
          descriptionFontFamily={descriptionFontFamily}
          mobileDescriptionFontFamily={mobileDescriptionFontFamily}
          descriptionLineHeight={descriptionLineHeight}
          mobileDescriptionLineHeight={mobileDescriptionLineHeight}
          descriptionColor={descriptionColor}
        />
      )}
      {needOneShotsSummary && (
        <>
          <DividerUi />
          <OneShotsSummary
            plan={activePricePlan}
            bumpPlan={bumpPlan}
            coupon={coupon}
            tax={tax}
            bumpTax={bumpTax}
            quantity={quantity}
            isVatNotChargeable={isVatNotChargeable}
            locale={locale}
            amountFontSize={amountFontSize}
            mobileAmountFontSize={mobileAmountFontSize}
            amountFontStyle={amountFontStyle}
            mobileAmountFontStyle={mobileAmountFontStyle}
            amountFontWeight={amountFontWeight}
            mobileAmountFontWeight={mobileAmountFontWeight}
            amountFontFamily={amountFontFamily}
            mobileAmountFontFamily={mobileAmountFontFamily}
            amountLineHeight={amountLineHeight}
            mobileAmountLineHeight={mobileAmountLineHeight}
            amountColor={amountColor}
            descriptionFontSize={descriptionFontSize}
            mobileDescriptionFontSize={mobileDescriptionFontSize}
            descriptionFontStyle={descriptionFontStyle}
            mobileDescriptionFontStyle={mobileDescriptionFontStyle}
            descriptionFontWeight={descriptionFontWeight}
            mobileDescriptionFontWeight={mobileDescriptionFontWeight}
            descriptionFontFamily={descriptionFontFamily}
            mobileDescriptionFontFamily={mobileDescriptionFontFamily}
            descriptionLineHeight={descriptionLineHeight}
            mobileDescriptionLineHeight={mobileDescriptionLineHeight}
            descriptionColor={descriptionColor}
          />
        </>
      )}
    </div>
  )
}

export default OfferPrice
