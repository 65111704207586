import axios from 'axios'
import { PaymentRequestPayload } from 'publisher/hooks/usePaymentSubmit'

export function buyMainOffer(
  pageId: number,
  body: {
    payment_form: PaymentRequestPayload
  },
) {
  return axios.post(`/api/purchase-service/buy-main-offer/${pageId}`, body)
}

export function buyUpsellOffer(
  pageId: number,
  purchaseProcessId: number,
  body: any,
) {
  return axios.post(
    `/api/purchase-service/buy-upsell/${pageId}/${purchaseProcessId}`,
    body,
  )
}
