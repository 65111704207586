import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { BadRequest } from 'common/errors'
import { OldEntityInterface } from '../../common/types/entities/OldEntityInterface'
import { optInFail } from '../actions/optInActions'
import * as api from '../api/paypal'
import loadScript from '../utils/loadScript'
import usePage, { selectors as pageSelectors } from './usePage'
import usePayment, { selectors as paymentSelectors } from './usePayment'
import usePaymentSubmit from './usePaymentSubmit'

export default function usePaypalPaymentHandler(entity: OldEntityInterface) {
  const dispatch = useDispatch()
  const { errors, setErrors, isLoading, submit } = usePaymentSubmit(entity)
  const [isScriptLoading, setScriptLoading] = useState(true)
  const pageId = usePage(pageSelectors.getPageId)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)

  useEffect(() => {
    loadScript(
      'https://www.paypalobjects.com/api/checkout.min.js',
      'paypal-js',
      () => setScriptLoading(false),
    )
  }, [])

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    submit(async body => {
      // @ts-ignore
      window.paypal.checkout.initXO()
      try {
        await api.validateForm(pageId, body, purchaseProcessId)
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
          // @ts-ignore
          window.paypal.checkout.closeFlow()
        }
        return
      }

      try {
        const calculatedFormProperties = body.product
          ? {
              product: {
                id: body.product.id,
                variant: body.product.variant,
                quantity: body.product.quantity,
              },
            }
          : {
              offerPricePlan: body.offerPricePlan,
            }

        const { data } = await api.purchaseDetails(
          pageId,
          calculatedFormProperties,
          purchaseProcessId,
        )

        if (data.redirect) {
          // @ts-ignore
          window.paypal.checkout.closeFlow()
          window.location.assign(data.redirect)
          return
        }

        // @ts-ignore
        window.paypal.checkout.startFlow(data.approvalUrl)
      } catch (error) {
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        }
        // @ts-ignore
        window.paypal.checkout.closeFlow()
      }
    })
  }

  return { errors, isLoading, handleSubmit, isScriptLoading }
}
