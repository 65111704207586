import { CountdownActionsEnum } from 'common/components/entities/Countdown/enums/CountdownActionsEnum'
import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import { addUrlSchemaToTheLink, isUrlCurrent } from 'common/utils/urlUtils'
import { PaymentState } from 'publisher/store'

export const timeEndHandler = ({
  entity,
  deadlineExpirationUrl,
}: {
  entity: CountdownInterface
  deadlineExpirationUrl: PaymentState['deadlineExpirationUrl'] | null
}) => {
  const { redirectUrl, action, countdownType } = entity
  if (typeof window === 'undefined') {
    return
  }
  const isUrlNotSameAsCurrent = !isUrlCurrent(redirectUrl, window.location.href)
  if (redirectUrl) {
    if (action === CountdownActionsEnum.redirection && isUrlNotSameAsCurrent) {
      window.location.href = addUrlSchemaToTheLink(redirectUrl)
    }
  }

  if (countdownType === CountdownTypesEnum.deadline) {
    if (deadlineExpirationUrl && isUrlNotSameAsCurrent) {
      window.location.assign(addUrlSchemaToTheLink(deadlineExpirationUrl))
    }
  }
}
