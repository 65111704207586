import { useCallback, useEffect } from 'react'
import { ProductInterface } from 'common/types/entities/ProductInterface'
import { PricePlan } from '../../common/types/OfferInterface'
import { getPricePlanAmount } from '../../common/utils/priceCalculator'
import { RazorpayBuyOfferResponse } from '../api/razorpayApi'

type RazorpaySuccessHandlerArgs = {
  razorpay_signature: string
  razorpay_order_id?: string
  razorpay_subscription_id?: string
  razorpay_payment_id: string
}

export interface RazorpayOptions {
  key: string
  amount: number
  currency: string
  name: string
  description?: string
  image?: string
  order_id?: string
  subscription_id?: string
  handler: (args: RazorpaySuccessHandlerArgs) => void
  prefill?: {}
  notes?: {}
  theme?: {}
  modal?: {}
}

interface Constructable<T> {
  new (options: RazorpayOptions): T
}

declare global {
  interface Window {
    Razorpay: Constructable<Razorpay>
  }
}

class Razorpay {
  private readonly options: RazorpayOptions
  private razorpayInstance: any

  constructor(options: RazorpayOptions) {
    this.options = options
    if (typeof window !== 'undefined') {
      this.razorpayInstance = new window.Razorpay(this.options)
    }
  }

  public on(event: string, callback: Function) {
    this.razorpayInstance.on(event, callback)
  }

  public open() {
    this.razorpayInstance.open()
  }
}

export default function useRazorpay() {
  const RAZORPAY_SCRIPT = 'https://checkout.razorpay.com/v1/checkout.js'

  const loadScript = useCallback((scriptUrl: string) => {
    return new Promise((resolve, reject) => {
      const scriptTag = document.createElement('script')
      scriptTag.src = scriptUrl
      scriptTag.onload = ev => resolve(ev)
      scriptTag.onerror = err => reject(err)
      document.body.appendChild(scriptTag)
    })
  }, [])

  const generateOptions = function (
    purchaseDetails: RazorpayBuyOfferResponse,
    pricePlan: PricePlan | undefined | null,
    isBump = false,
    product: ProductInterface | null = {} as ProductInterface,
  ) {
    const calculatedAmount = pricePlan
      ? getPricePlanAmount(pricePlan)
      : (product as ProductInterface).price
    const calculatedCurrency = pricePlan
      ? pricePlan?.currency.toUpperCase()
      : (product as ProductInterface).currency.toUpperCase()

    const options: RazorpayOptions = {
      key: purchaseDetails.keyId,
      amount: calculatedAmount,
      currency: calculatedCurrency,
      name: purchaseDetails.vendorName,
      description: pricePlan?.name,
      prefill: {
        name: purchaseDetails.prefill.name,
        email: purchaseDetails.prefill.email,
        contact: purchaseDetails.prefill.phone,
      },
      theme: {
        color: '#3399cc',
      },
      handler: async () => {
        window.location.assign(purchaseDetails.redirectUrl)
      },
    }

    if (isBump) {
      if (purchaseDetails.rpBumpOrderId) {
        options.order_id = purchaseDetails.rpBumpOrderId
      } else if (purchaseDetails.rpBumpSubscriptionId) {
        options.subscription_id = purchaseDetails.rpBumpSubscriptionId
      } else {
        throw Error('RP bump order id and bump subscription id is missing')
      }

      options.order_id = purchaseDetails.rpBumpOrderId
    } else {
      if (purchaseDetails.rpOrderId) {
        options.order_id = purchaseDetails.rpOrderId
      } else if (purchaseDetails.rpSubscriptionId) {
        options.subscription_id = purchaseDetails.rpSubscriptionId
      } else {
        throw Error('RP order id and subscription id is missing')
      }
    }

    return options
  }

  useEffect(() => {
    if (!('Razorpay' in window)) {
      ;(async () => {
        try {
          await loadScript(RAZORPAY_SCRIPT)
        } catch (error: any) {
          throw new Error(error)
        }
      })()
    }
  }, [])

  return { Razorpay, generateOptions }
}
