import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ProductLabelUi from './ui/ProductLabelUi'
import ProductQuantityIconContainerUi from './ui/ProductQuantityIconContainerUi'

const ProductQuantityContainerUi = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

const ProductQuantityInnerContainerUi = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

const ProductQuantityLabelContainerUi = styled.div`
  font-style: normal;
  font-weight: 400;
  color: #8196ab;
`

interface ProductQuantityProps {
  decreaseQuantity?: () => void
  increaseQuantity?: () => void
  quantity: number
  showQuantity: boolean
}

function ProductQuantity({
  quantity,
  decreaseQuantity,
  increaseQuantity,
  showQuantity,
}: ProductQuantityProps) {
  const { t } = useTranslation('common')
  return (
    <>
      {showQuantity && (
        <ProductQuantityContainerUi>
          <ProductLabelUi>{t('entity.product.quantity_label')}</ProductLabelUi>
          <ProductQuantityInnerContainerUi>
            <ProductQuantityIconContainerUi
              onClick={() => decreaseQuantity && decreaseQuantity()}
            >
              -
            </ProductQuantityIconContainerUi>
            <ProductQuantityLabelContainerUi>
              {quantity}
            </ProductQuantityLabelContainerUi>
            <ProductQuantityIconContainerUi
              onClick={() => increaseQuantity && increaseQuantity()}
            >
              +
            </ProductQuantityIconContainerUi>
          </ProductQuantityInnerContainerUi>
        </ProductQuantityContainerUi>
      )}
    </>
  )
}

export default ProductQuantity
