export enum CurrencyEnum {
  AUSTRALIAN_DOLLAR = 'aud',
  CANADIAN_DOLLAR = 'cad',
  DANISH_KRONE = 'dkk',
  EURO = 'eur',
  HONG_KONG_DOLLAR = 'hkd',
  ISRAELI_NEW_SHEKEL = 'ils',
  JAPANESE_YEN = 'jpy',
  MEXICAN_PESO = 'mxn',
  NEW_ZEALAND_DOLLAR = 'nzd',
  NORWEGIAN_KRONE = 'nok',
  PHILIPPINE_PESO = 'php',
  POLISH_ZLOTY = 'pln',
  POUND_STERLING = 'gbp',
  RUSSIAN_RUBLE = 'rub',
  SINGAPORE_DOLLAR = 'sgd',
  SWEDISH_KRONA = 'sek',
  SWISS_FRANC = 'chf',
  THAI_BAHT = 'thb',
  US_DOLLAR = 'usd',
  BRAZILIAN_REAL = 'brl',
  INDIAN_RUPEE = 'inr',
  MALAYSIAN_RINGGIT = 'myr',
  CZECH_CROWN = 'czk',
  ROMANIA_LEI = 'ron',
  UNITED_ARAB_EMIRATES_DIRHAM = 'aed',
  HUNGARIAN_FORINT = 'huf',
  COLOMBIAN_PESO = 'cop',
  CHILEAN_PESO = 'clp',
  ARGENTINE_PESO = 'ars',
  SAUDI_RIYAL = 'sar',
  CENTRAL_AFRICAN_FRANC = 'xaf',
  EGYPTIAN_POUND = 'egp',
  GHANAIAN_CEDI = 'ghs',
  GUINEAN_FRANC = 'gnf',
  KENYAN_SHILLING = 'kes',
  MALAWIAN_KWACHA = 'mwk',
  MOROCCAN_DIRHAM = 'mad',
  NIGERIAN_NAIRA = 'ngn',
  RWANDAN_FRANC = 'rwf',
  SIERRA_LEONEAN_LEONE = 'sll',
  SAO_TOME_AND_PRINCIPE_DOBRA = 'std',
  SOUTH_AFRICAN_RAND = 'zar',
  TANZANIAN_SHILLING = 'tzs',
  UGANDAN_SHILLING = 'ugx',
  WEST_AFRICAN_CFA_FRANC_BCEAO = 'xof',
  ZAMBIAN_KWACHA = 'zmw',
  TURKISH_LIRA = 'try',
  INDONESIAN_RUPIAH = 'idr',
  SRI_LANKAN_RUPEE = 'lkr',
}

export const currencySigns = {
  [CurrencyEnum.AUSTRALIAN_DOLLAR]: 'AU$',
  [CurrencyEnum.CANADIAN_DOLLAR]: 'Can$',
  [CurrencyEnum.DANISH_KRONE]: 'kr(DKK)',
  [CurrencyEnum.EURO]: '€',
  [CurrencyEnum.HONG_KONG_DOLLAR]: 'HK$',
  [CurrencyEnum.ISRAELI_NEW_SHEKEL]: '₪',
  [CurrencyEnum.JAPANESE_YEN]: '¥',
  [CurrencyEnum.MEXICAN_PESO]: 'Mex$',
  [CurrencyEnum.NEW_ZEALAND_DOLLAR]: 'NZ$',
  [CurrencyEnum.NORWEGIAN_KRONE]: 'NKr',
  [CurrencyEnum.PHILIPPINE_PESO]: '₱',
  [CurrencyEnum.POLISH_ZLOTY]: 'zł',
  [CurrencyEnum.POUND_STERLING]: '£',
  [CurrencyEnum.RUSSIAN_RUBLE]: '₽',
  [CurrencyEnum.SINGAPORE_DOLLAR]: 'S$',
  [CurrencyEnum.SWEDISH_KRONA]: 'kr(SEK)',
  [CurrencyEnum.SWISS_FRANC]: 'CHF',
  [CurrencyEnum.THAI_BAHT]: '฿',
  [CurrencyEnum.US_DOLLAR]: 'US$',
  [CurrencyEnum.BRAZILIAN_REAL]: 'R$',
  [CurrencyEnum.INDIAN_RUPEE]: 'Rp',
  [CurrencyEnum.MALAYSIAN_RINGGIT]: 'RM',
  [CurrencyEnum.CZECH_CROWN]: 'Kč',
  [CurrencyEnum.ROMANIA_LEI]: 'L',
  [CurrencyEnum.UNITED_ARAB_EMIRATES_DIRHAM]: 'AED',
  [CurrencyEnum.HUNGARIAN_FORINT]: 'Ft',
  [CurrencyEnum.COLOMBIAN_PESO]: 'COL$',
  [CurrencyEnum.CHILEAN_PESO]: 'CLP$',
  [CurrencyEnum.ARGENTINE_PESO]: 'Arg$',
  [CurrencyEnum.SAUDI_RIYAL]: 'SAR',
  [CurrencyEnum.CENTRAL_AFRICAN_FRANC]: 'F.CFA',
  [CurrencyEnum.EGYPTIAN_POUND]: 'E£',
  [CurrencyEnum.GHANAIAN_CEDI]: 'GH₵',
  [CurrencyEnum.GUINEAN_FRANC]: 'FG',
  [CurrencyEnum.KENYAN_SHILLING]: 'KES',
  [CurrencyEnum.MALAWIAN_KWACHA]: 'MK',
  [CurrencyEnum.MOROCCAN_DIRHAM]: 'DH',
  [CurrencyEnum.NIGERIAN_NAIRA]: '₦',
  [CurrencyEnum.RWANDAN_FRANC]: 'FRw',
  [CurrencyEnum.SIERRA_LEONEAN_LEONE]: 'Le',
  [CurrencyEnum.SAO_TOME_AND_PRINCIPE_DOBRA]: 'Db',
  [CurrencyEnum.SOUTH_AFRICAN_RAND]: 'R',
  [CurrencyEnum.TANZANIAN_SHILLING]: 'TSh',
  [CurrencyEnum.UGANDAN_SHILLING]: 'USh',
  [CurrencyEnum.WEST_AFRICAN_CFA_FRANC_BCEAO]: 'CFA',
  [CurrencyEnum.ZAMBIAN_KWACHA]: 'ZK',
  [CurrencyEnum.TURKISH_LIRA]: '₺',
  [CurrencyEnum.INDONESIAN_RUPIAH]: 'Rp',
  [CurrencyEnum.SRI_LANKAN_RUPEE]: 'Rs',
}
