import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import CardFieldUi from 'common/components/entities/PaymentMethods/ui/CardFieldUi'
import CardLabelUi from 'common/components/entities/PaymentMethods/ui/CardLabelUi'
import CardRowUi from 'common/components/entities/PaymentMethods/ui/CardRowUi'
import FieldErrors from 'publisher/components/FieldErrors'
import { useMercadoPago } from 'publisher/context/mercadoPagoContext'
import { CustomTaxNumberOptionsKeys } from 'publisher/pages/offer-page/constants/custom-tax-number-options'
import { useCustomTaxNumber } from 'publisher/pages/offer-page/hooks/useCustomTaxNumber'
import { getCleanIdentificationNumberValue } from 'publisher/pages/offer-page/utils/getCleanIdentificationNumberValue'
import { usePage } from 'publisher/store'
import { getTaxNumberFieldEntity } from 'publisher/store/page/pageSelectors'
import CreditCardInputUi from '../ui/CreditCardInputUi'
import CreditCardInputWrapperUi from '../ui/CreditCardInputWrapperUi'
import CreditCardSelectUi from '../ui/CreditCardSelectUi'
import { getFormattedCardExpiry } from '../utils/paymentCreditCardUtils'

interface MercadoPagoCreditCardProps {
  type: CustomTaxNumberOptionsKeys
}
function MercadoPagoCreditCard({ type }: MercadoPagoCreditCardProps) {
  const [month, setMonth] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const monthRef = useRef<HTMLInputElement>(null)
  const cardNumberRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)
  const identificationNumberRef = useRef<HTMLInputElement>(null)
  const [year, setYear] = useState('')
  const { errors, resetError, areInstallmentsVisible } = useMercadoPago()
  const taxNumberOptions = useCustomTaxNumber(type)
  const { t } = useTranslation(['common', 'publisher'])
  const taxNumberField = usePage(getTaxNumberFieldEntity)
  //we need clean numbers for mp sdk

  function changeCardNumber({ value }: NumberFormatValues) {
    if (cardNumberRef.current) {
      setCardNumber(value)
      cardNumberRef.current.dispatchEvent(new Event('input', { bubbles: true }))
    }
    if (errors.cardNumber) {
      resetError('cardNumber')
    }
  }

  function changeCardExpiry({ value }: NumberFormatValues) {
    setMonth(value.substring(0, 2))
    if (monthRef.current) {
      monthRef.current.dispatchEvent(new Event('input', { bubbles: true }))
    }
    if (yearRef.current) {
      yearRef.current.dispatchEvent(new Event('input', { bubbles: true }))
    }
    setYear(value.substring(2, 4))
    if (errors.cardExpiration) {
      resetError('cardExpiration')
    }
  }

  function handleTaxNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    taxNumberOptions.change(event.target.value)
    if (errors.identificationNumber) {
      resetError('identificationNumber')
    }
    //not sure for what it is needed
    if (identificationNumberRef.current) {
      identificationNumberRef.current.dispatchEvent(
        new Event('input', { bubbles: true }),
      )
    }
  }

  function handleCardholderNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.value = e.target.value.toUpperCase()
    if (errors.cardholderName) {
      resetError('cardholderName')
    }
  }

  function handleCvv() {
    if (errors.securityCode) {
      resetError('securityCode')
    }
  }

  return (
    <>
      <CardRowUi>
        <CardFieldUi width="70%">
          <CardLabelUi htmlFor="mercado-card-number-mask">
            {t('components.payment_methods.card_number')}
          </CardLabelUi>
          <CreditCardInputWrapperUi hasErrors={Boolean(errors.cardNumber)}>
            <NumberFormat
              id="mercado-card-number-mask"
              format="#### #### #### ####"
              placeholder="1234 1234 1234 1234"
              customInput={CreditCardInputUi}
              onValueChange={changeCardNumber}
            />
          </CreditCardInputWrapperUi>
          {errors.cardNumber && <FieldErrors errors={[errors.cardNumber]} />}
        </CardFieldUi>
        <CardFieldUi width="30%">
          <CardLabelUi htmlFor="mercado-card-expiration-month">
            {t('components.payment_methods.expiration_date')}
          </CardLabelUi>
          <div style={{ display: 'flex' }}>
            <CreditCardInputWrapperUi
              hasErrors={Boolean(errors.cardExpiration)}
            >
              <NumberFormat
                format={getFormattedCardExpiry}
                placeholder="MM / YY"
                customInput={CreditCardInputUi}
                onValueChange={changeCardExpiry}
              />
            </CreditCardInputWrapperUi>
          </div>
          {errors.cardExpiration && (
            <FieldErrors errors={[errors.cardExpiration]} />
          )}
        </CardFieldUi>
      </CardRowUi>
      <CardRowUi>
        <CardFieldUi width="70%">
          <CardLabelUi htmlFor="mercado-card-holder-name">
            {t('components.payment_methods.card_holder_name')}
          </CardLabelUi>
          <CreditCardInputWrapperUi
            hasErrors={Boolean(errors['cardholderName'])}
          >
            <CreditCardInputUi
              type="text"
              id="mercado-card-holder-name"
              onChange={handleCardholderNameChange}
            />
          </CreditCardInputWrapperUi>
          {errors.cardholderName && (
            <FieldErrors errors={[errors.cardholderName]} />
          )}
        </CardFieldUi>
        <CardFieldUi width="30%">
          <CardLabelUi htmlFor="mercado-card-security_code">
            {t('components.payment_methods.security_code')}
          </CardLabelUi>
          <CreditCardInputWrapperUi hasErrors={Boolean(errors.securityCode)}>
            <CreditCardInputUi
              id="mercado-card-security_code"
              onChange={handleCvv}
              maxLength={4}
            />
          </CreditCardInputWrapperUi>
          {errors.securityCode && (
            <FieldErrors errors={[errors.securityCode]} />
          )}
        </CardFieldUi>
      </CardRowUi>
      <CardRowUi>
        {!taxNumberField && (
          <CardFieldUi>
            <CardLabelUi htmlFor="mercado-card-identification-number">
              {t('components.payment_methods.identification_number')}
            </CardLabelUi>
            <CreditCardInputWrapperUi
              hasErrors={Boolean(
                errors.identificationNumber ||
                  taxNumberOptions.errors.length > 0,
              )}
            >
              <CreditCardInputUi
                value={taxNumberOptions.value}
                onChange={handleTaxNumberChange}
              />
            </CreditCardInputWrapperUi>
            {(errors.identificationNumber || taxNumberOptions.errors) && (
              <FieldErrors
                errors={
                  errors.identificationNumber
                    ? [errors.identificationNumber]
                    : taxNumberOptions.errors
                }
              />
            )}
          </CardFieldUi>
        )}
      </CardRowUi>
      <select
        id="form-checkout__issuer"
        name="issuer"
        style={{ display: 'none' }}
      />
      {areInstallmentsVisible ? (
        <CardRowUi>
          <CardFieldUi>
            <CardLabelUi htmlFor="mercado-card-installments">
              {t('components.payment_methods.installments')}
            </CardLabelUi>
            <CreditCardSelectUi
              id="mercado-card-installments"
              name="installments"
            />
          </CardFieldUi>
        </CardRowUi>
      ) : (
        <select
          id="mercado-card-installments"
          name="installments"
          style={{ display: 'none' }}
        />
      )}
      <input
        value={cardNumber}
        id="mercado-card-number"
        type="hidden"
        ref={cardNumberRef}
      />
      <input
        value={month}
        id="mercado-card-expiration-month"
        type="hidden"
        ref={monthRef}
      />
      <input
        value={year}
        id="mercado-card-expiration-year"
        type="hidden"
        ref={yearRef}
      />
      <input
        id="mercado-card-identification-number"
        type="hidden"
        value={getCleanIdentificationNumberValue(taxNumberOptions.value)}
        ref={identificationNumberRef}
      />
      <input
        type="hidden"
        value={taxNumberOptions.identificationType}
        id="mercado-card-identification-type"
      />
    </>
  )
}

export default MercadoPagoCreditCard
