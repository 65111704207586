import axios from 'axios'

export function buyMainOffer(
  pageId: number,
  purchaseProcessId: number,
  body: any,
) {
  return axios.post(
    `/secure/flutterwave/${pageId}/buy-main-offer/${purchaseProcessId}`,
    body,
  )
}

export function buyUpsellOffer(
  pageId: number,
  purchaseProcessId: number,
  body: any,
) {
  return axios.post(
    `/secure/flutterwave/${pageId}/buy-upsell/${purchaseProcessId}`,
    body,
  )
}
