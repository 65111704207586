export enum PageLocaleEnum {
  FRENCH = 'fr',
  ENGLISH = 'en',
  SPANISH = 'es',
  ITALIAN = 'it',
  PORTUGUESE = 'pt',
  DEUTSCH = 'de',
  DUTCH = 'nl',
  RUSSIAN = 'ru',
  JAPANESE = 'jp',
  ARABIC = 'ar',
  TURKISH = 'tr',
  CHINESE = 'zh',
}
