import { Component } from 'react'

class ErrorBoundary extends Component {
  state = { hasError: false }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    window.Rollbar.error(error)
  }

  render() {
    return this.state.hasError ? 'error' : this.props.children
  }
}

export default ErrorBoundary
