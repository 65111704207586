import { useState } from 'react'
import { UpsellButtonInterface } from 'common/types/entities/ButtonInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { usePayment, usePage, useManagement } from 'publisher/store'
import managementSelectors from 'publisher/store/management/managementSelectors'
import pageSelectors from 'publisher/store/page/pageSelectors'
import typedPaymentSelectors from 'publisher/store/payment/paymentSelectors'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'

export default function useUpsellSubmit(entity: UpsellButtonInterface) {
  const [isLoading, setLoading] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const checkedPricePlanId = usePayment(paymentSelectors.getCheckedPricePlanId)
  const isDesktop = useManagement(managementSelectors.isDesktop)
  const popupId = usePage(p => pageSelectors.getAscendantPopupId(p, entity))

  const mandatoryExplicitConsents = usePage(p =>
    pageSelectors.getVisibleMandatoryExplicitConsents(p, isDesktop, popupId),
  )
  const confirmedExplicitConsentsIds = useManagement(
    managementSelectors.getConfirmedExplicitConsentsIds,
  )
  const product = usePayment(typedPaymentSelectors.getProduct)
  const productQuantity = usePayment(typedPaymentSelectors.getProductQuantity)
  const activeProductVariant = usePayment(
    typedPaymentSelectors.getProductActiveVariant,
  )
  const submit = async (paymentMethodHandler: any) => {
    setErrors([])

    const explicitConsentsErrors = mandatoryExplicitConsents.reduce(
      (errors: string[], explicitConsent: OldEntityInterface) => {
        return confirmedExplicitConsentsIds.includes(explicitConsent.id)
          ? errors
          : [...errors, explicitConsent.options.errorText]
      },
      [],
    )

    if (explicitConsentsErrors.length > 0) {
      setErrors(explicitConsentsErrors)
      return
    }
    const calculatedFormProperties = product
      ? {
          product: {
            id: product?.id,
            variant: activeProductVariant?.id,
            quantity: productQuantity,
          },
        }
      : {
          offerPricePlan: checkedPricePlanId,
        }
    setLoading(true)
    try {
      await paymentMethodHandler({
        ...calculatedFormProperties,
      })
    } catch (error) {
      // there we can catch only errors like TypeError or SyntaxError (not related to Response)
      // @ts-ignore
      window.Rollbar.error(error)
    }
    setLoading(false)
  }

  return { errors, setErrors, isLoading, submit }
}
