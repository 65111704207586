import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'

export default function getImageSrcAndDimensions(
  paymentMethod: PaymentMethodEnum,
) {
  const host = `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/images/payment/`
  switch (paymentMethod) {
    case PaymentMethodEnum.StripeCard:
    case PaymentMethodEnum.DlocalCard:
    case PaymentMethodEnum.MercadoPagoCard:
    case PaymentMethodEnum.MercadoPagoCoCard:
    case PaymentMethodEnum.MercadoPagoMxCard:
    case PaymentMethodEnum.MercadoPagoArCard:
    case PaymentMethodEnum.MercadoPagoClCard:
    case PaymentMethodEnum.MercadoPagoBrCard:
    case PaymentMethodEnum.XenditIdCard:
    case PaymentMethodEnum.XenditPhCard:
    case PaymentMethodEnum.PaystackGhCard:
    case PaymentMethodEnum.PaystackKeCard:
    case PaymentMethodEnum.PaystackNgCard:
    case PaymentMethodEnum.PaystackZaCard:
      return { src: `${host}credit_cards.png`, width: 223, height: 36 }
    case PaymentMethodEnum.StripeBancontact:
      return { src: `${host}bancontact.png`, width: 63, height: 39 }
    case PaymentMethodEnum.StripeIDeal:
      return { src: `${host}ideal.png`, width: 41, height: 36 }
    case PaymentMethodEnum.PaypalSoap:
      return { src: `${host}paypal.png`, width: 115, height: 28 }
    case PaymentMethodEnum.DlocalBoleto:
    case PaymentMethodEnum.MercadoPagoBoleto:
    case PaymentMethodEnum.MercadoPagoBrBoleto:
      return { src: `${host}boleto.png`, width: 63, height: 40 }
    case PaymentMethodEnum.DlocalPix:
    case PaymentMethodEnum.MercadoPagoPix:
    case PaymentMethodEnum.MercadoPagoBrPix:
      return { src: `${host}pix.png`, width: 80, height: 28 }
    case PaymentMethodEnum.RazorpayCard:
      return { src: `${host}razorpay.svg`, width: 170, height: 36 }
    case PaymentMethodEnum.Flutterwave:
      return { src: `${host}flutterwave.svg`, width: 185, height: 36 }
    case PaymentMethodEnum.Mollie:
      return { src: `${host}mollie.png`, width: 238, height: 60 }
    case PaymentMethodEnum.Gocardless:
      return { src: `${host}gocardless.svg`, width: 115, height: 28 }
    default:
      return null
  }
}
