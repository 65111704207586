import React from 'react'
import { useTranslation } from 'react-i18next'
import { CouponTypeEnum } from 'common/enums/CouponTypeEnum'
import { Coupon } from 'common/types/CouponType'
import { ProductInfoInterface } from 'common/types/entities/ProductInterface'
import { centsToEuro } from 'common/utils/priceCalculator'
import PricePlanAmountUi from '../ui/PricePlanAmountUi'
import PricePlanDescriptionUi from '../ui/PricePlanDescriptionUi'
import PricePlanItemWithoutLeftOffsetUi from '../ui/PricePlanItemWithoutLeftOffsetUi'
import PricePlanNameUi from '../ui/PricePlanNameUi'
import PricePlanUi from '../ui/PricePlanUi'

interface ProductProps {
  productInfo?: ProductInfoInterface
  coupon?: Coupon
  nameFontFamily?: string
  mobileNameFontFamily?: string
  nameFontSize: number
  mobileNameFontSize?: number
  nameFontStyle?: string
  mobileNameFontStyle?: string
  nameFontWeight?: string
  mobileNameFontWeight?: string
  nameLineHeight: number
  mobileNameLineHeight?: number
  nameColor: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
}

function Product({
  productInfo,
  coupon,
  nameFontSize,
  mobileNameFontSize,
  nameFontStyle,
  mobileNameFontStyle,
  nameFontWeight,
  mobileNameFontWeight,
  nameFontFamily,
  mobileNameFontFamily,
  nameLineHeight,
  mobileNameLineHeight,
  nameColor,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
}: ProductProps) {
  const { t } = useTranslation(['common', 'publisher'])
  const needTotal =
    productInfo && productInfo.name && (productInfo.taxAmount || coupon)

  return (
    <PricePlanUi>
      {productInfo && productInfo.name && (
        <>
          <PricePlanItemWithoutLeftOffsetUi>
            <PricePlanNameUi
              fontSize={nameFontSize}
              mobileFontSize={mobileNameFontSize}
              fontStyle={nameFontStyle}
              mobileFontStyle={mobileNameFontStyle}
              fontWeight={nameFontWeight}
              mobileFontWeight={mobileNameFontWeight}
              fontFamily={nameFontFamily}
              mobileFontFamily={mobileNameFontFamily}
              lineHeight={nameLineHeight}
              mobileLineHeight={mobileNameLineHeight}
              color={nameColor}
            >
              {productInfo.name}
              <PricePlanDescriptionUi
                fontSize={descriptionFontSize}
                mobileFontSize={mobileDescriptionFontSize}
                fontStyle={descriptionFontStyle}
                mobileFontStyle={mobileDescriptionFontStyle}
                fontWeight={descriptionFontWeight}
                mobileFontWeight={mobileDescriptionFontWeight}
                fontFamily={descriptionFontFamily}
                mobileFontFamily={mobileDescriptionFontFamily}
                lineHeight={descriptionLineHeight}
                mobileLineHeight={mobileDescriptionLineHeight}
                color={descriptionColor}
              >
                {productInfo.variantDescription}
              </PricePlanDescriptionUi>
            </PricePlanNameUi>
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
            >
              {`${productInfo.currencySign}  ${productInfo.priceWithoutTaxAndCurrency}`}
            </PricePlanAmountUi>
          </PricePlanItemWithoutLeftOffsetUi>
          {!!productInfo.taxAmount && (
            <PricePlanItemWithoutLeftOffsetUi>
              <PricePlanDescriptionUi
                fontSize={descriptionFontSize}
                mobileFontSize={mobileDescriptionFontSize}
                fontStyle={descriptionFontStyle}
                mobileFontStyle={mobileDescriptionFontStyle}
                fontWeight={descriptionFontWeight}
                mobileFontWeight={mobileDescriptionFontWeight}
                fontFamily={descriptionFontFamily}
                mobileFontFamily={mobileDescriptionFontFamily}
                lineHeight={descriptionLineHeight}
                mobileLineHeight={mobileDescriptionLineHeight}
                color={descriptionColor}
              >
                {t('components.offer_price.vat')}
              </PricePlanDescriptionUi>
              <PricePlanAmountUi
                fontSize={amountFontSize}
                mobileFontSize={mobileAmountFontSize}
                fontStyle={amountFontStyle}
                mobileFontStyle={mobileAmountFontStyle}
                fontWeight={amountFontWeight}
                mobileFontWeight={mobileAmountFontWeight}
                fontFamily={amountFontFamily}
                mobileFontFamily={mobileAmountFontFamily}
                lineHeight={amountLineHeight}
                mobileLineHeight={mobileAmountLineHeight}
                color={amountColor}
              >
                {`${productInfo.currencySign}  ${productInfo.taxAmount}`}
              </PricePlanAmountUi>
            </PricePlanItemWithoutLeftOffsetUi>
          )}
        </>
      )}
      {coupon && productInfo && productInfo.name && (
        <>
          <PricePlanItemWithoutLeftOffsetUi>
            <PricePlanDescriptionUi
              fontSize={descriptionFontSize}
              mobileFontSize={mobileDescriptionFontSize}
              fontStyle={descriptionFontStyle}
              mobileFontStyle={mobileDescriptionFontStyle}
              fontWeight={descriptionFontWeight}
              mobileFontWeight={mobileDescriptionFontWeight}
              fontFamily={descriptionFontFamily}
              mobileFontFamily={mobileDescriptionFontFamily}
              lineHeight={descriptionLineHeight}
              mobileLineHeight={mobileDescriptionLineHeight}
              color={descriptionColor}
              data-test-id={`offer-price-product-${productInfo.name}`}
            >
              {t('components.offer_price.one_shots_summary.discount')}
            </PricePlanDescriptionUi>
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
            >
              {' '}
              -
              {coupon.discountType === CouponTypeEnum.Fixed
                ? `${productInfo.currencySign}  ${centsToEuro(
                    coupon.discountAmount,
                  )}`
                : `${coupon.discountAmount}%`}
            </PricePlanAmountUi>
          </PricePlanItemWithoutLeftOffsetUi>
        </>
      )}
      {!!needTotal && (
        <PricePlanItemWithoutLeftOffsetUi>
          <PricePlanDescriptionUi
            fontSize={descriptionFontSize}
            mobileFontSize={mobileDescriptionFontSize}
            fontStyle={descriptionFontStyle}
            mobileFontStyle={mobileDescriptionFontStyle}
            fontWeight={descriptionFontWeight}
            mobileFontWeight={mobileDescriptionFontWeight}
            fontFamily={descriptionFontFamily}
            mobileFontFamily={mobileDescriptionFontFamily}
            lineHeight={descriptionLineHeight}
            mobileLineHeight={mobileDescriptionLineHeight}
            color={descriptionColor}
          >
            {t('components.offer_price.total')}
          </PricePlanDescriptionUi>
          <PricePlanAmountUi
            fontSize={amountFontSize}
            mobileFontSize={mobileAmountFontSize}
            fontStyle={amountFontStyle}
            mobileFontStyle={mobileAmountFontStyle}
            fontWeight={amountFontWeight}
            mobileFontWeight={mobileAmountFontWeight}
            fontFamily={amountFontFamily}
            mobileFontFamily={mobileAmountFontFamily}
            lineHeight={amountLineHeight}
            mobileLineHeight={mobileAmountLineHeight}
            color={amountColor}
          >
            {`${productInfo.currencySign}  ${productInfo.netPrice}`}
          </PricePlanAmountUi>
        </PricePlanItemWithoutLeftOffsetUi>
      )}
    </PricePlanUi>
  )
}
export default Product
