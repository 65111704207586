import axios from 'axios'

export function buyMainOffer(
  pageId: number,
  purchaseProcessId: number,
  body: any,
) {
  return axios.post(
      `/api/purchase-service/buy-main-offer/${pageId}`,
    body,
  )
}

export function buyUpsellOffer(
  pageId: number,
  purchaseProcessId: number,
  body: any,
) {
  return axios.post(
    `/api/purchase-service/buy-upsell/${pageId}/${purchaseProcessId}`,
    body,
  )
}
