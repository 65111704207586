import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import ErrorBoundary from 'publisher/components/ErrorBoundary'
import FieldErrors from 'publisher/components/FieldErrors'
import {
  formOptions,
  useMercadoPago,
} from 'publisher/context/mercadoPagoContext'
import { CustomTaxNumberOptionsKeys } from 'publisher/pages/offer-page/constants/custom-tax-number-options'
import { useCustomTaxNumber } from 'publisher/pages/offer-page/hooks/useCustomTaxNumber'
import { getCleanIdentificationNumberValue } from 'publisher/pages/offer-page/utils/getCleanIdentificationNumberValue'
import { usePage } from 'publisher/store'
import { getTaxNumberFieldEntity } from 'publisher/store/page/pageSelectors'
import CreditCardFieldUi from '../../shared/ui/CreditCardFieldUi'
import CreditCardInputUi from '../../shared/ui/CreditCardInputUi'
import CreditCardInputWrapperUi from '../../shared/ui/CreditCardInputWrapperUi'
import CreditCardLabelUi from '../../shared/ui/CreditCardLabelUi'
import CreditCardRowUi from '../../shared/ui/CreditCardRowUi'
import CreditCardSelectUi from '../../shared/ui/CreditCardSelectUi'
import getFormattedCardExpiry from '../../shared/utils/get-formatted-card-expiry'
import {
  mercadoPagoCardHolderNameId,
  mercadoPagoCardIdentificationNumberId,
  mercadoPagoCardNumberId,
  mercadoPagoCardSecurityCodeId,
} from './constants/creditCardFieldIds'

interface MercadoPagoCreditCardProps {
  type: CustomTaxNumberOptionsKeys
}
function MercadoPagoCreditCard({ type }: MercadoPagoCreditCardProps) {
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)
  const cardNumberRef = useRef<HTMLInputElement>(null)
  const identificationNumberRef = useRef<HTMLInputElement>(null)
  const { errors, resetError, areInstallmentsVisible } = useMercadoPago()
  const taxNumberOptions = useCustomTaxNumber(type)
  const { t } = useTranslation(['common', 'publisher'])
  const taxNumberField = usePage(getTaxNumberFieldEntity)
  //we need clean numbers for mp sdk

  function changeCardNumber({ value }: NumberFormatValues) {
    if (cardNumberRef.current) {
      setCardNumber(value)
      cardNumberRef.current.dispatchEvent(new Event('input', { bubbles: true }))
    }
    if (errors.cardNumber) {
      resetError('cardNumber')
    }
  }

  function changeCardExpiry({ value }: NumberFormatValues) {
    setMonth(value.substring(0, 2))
    if (monthRef.current) {
      monthRef.current.dispatchEvent(new Event('input', { bubbles: true }))
    }
    if (yearRef.current) {
      yearRef.current.dispatchEvent(new Event('input', { bubbles: true }))
    }
    setYear(value.substring(2, 4))
    if (errors.cardExpiration) {
      resetError('cardExpiration')
    }
  }

  function handleTaxNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    taxNumberOptions.change(event.target.value)
    if (errors.identificationNumber) {
      resetError('identificationNumber')
    }
    //not sure for what it is needed
    if (identificationNumberRef.current) {
      identificationNumberRef.current.dispatchEvent(
        new Event('input', { bubbles: true }),
      )
    }
  }

  function handleCardholderNameChange() {
    if (errors.cardholderName) {
      resetError('cardholderName')
    }
  }

  function handleCvv() {
    if (errors.securityCode) {
      resetError('securityCode')
    }
  }

  return (
    <ErrorBoundary>
      <CreditCardRowUi>
        <CreditCardFieldUi width="70%">
          <CreditCardLabelUi htmlFor={mercadoPagoCardNumberId}>
            {t('components.payment_methods.card_number')}
          </CreditCardLabelUi>
          <CreditCardInputWrapperUi hasErrors={Boolean(errors.cardNumber)}>
            <NumberFormat
              id={mercadoPagoCardNumberId}
              format="#### #### #### ####"
              placeholder="1234 1234 1234 1234"
              customInput={CreditCardInputUi}
              onValueChange={changeCardNumber}
            />
          </CreditCardInputWrapperUi>
          {errors.cardNumber && <FieldErrors errors={[errors.cardNumber]} />}
        </CreditCardFieldUi>
        <CreditCardFieldUi width="30%">
          <CreditCardLabelUi htmlFor={formOptions.cardExpirationMonth.id}>
            {t('components.payment_methods.expiration_date')}
          </CreditCardLabelUi>
          <div style={{ display: 'flex' }}>
            <CreditCardInputWrapperUi
              hasErrors={Boolean(errors.cardExpiration)}
            >
              <NumberFormat
                format={getFormattedCardExpiry}
                placeholder="MM / YY"
                customInput={CreditCardInputUi}
                onValueChange={changeCardExpiry}
              />
            </CreditCardInputWrapperUi>
          </div>
          {errors.cardExpiration && (
            <FieldErrors errors={[errors.cardExpiration]} />
          )}
        </CreditCardFieldUi>
      </CreditCardRowUi>
      <CreditCardRowUi>
        <CreditCardFieldUi width="70%">
          <CreditCardLabelUi htmlFor={formOptions.cardholderName.id}>
            {t('components.payment_methods.card_holder_name')}
          </CreditCardLabelUi>
          <CreditCardInputWrapperUi
            hasErrors={Boolean(errors['cardholderName'])}
          >
            <CreditCardInputUi
              type="text"
              id={formOptions.cardholderName.id}
              onChange={handleCardholderNameChange}
            />
          </CreditCardInputWrapperUi>
          {errors.cardholderName && (
            <FieldErrors errors={[errors.cardholderName]} />
          )}
        </CreditCardFieldUi>
        <CreditCardFieldUi width="30%">
          <CreditCardLabelUi htmlFor={formOptions.securityCode.id}>
            {t('components.payment_methods.security_code')}
          </CreditCardLabelUi>
          <CreditCardInputWrapperUi hasErrors={Boolean(errors.securityCode)}>
            <CreditCardInputUi
              id={formOptions.securityCode.id}
              onChange={handleCvv}
              maxLength={4}
            />
          </CreditCardInputWrapperUi>
          {errors.securityCode && (
            <FieldErrors errors={[errors.securityCode]} />
          )}
        </CreditCardFieldUi>
      </CreditCardRowUi>
      <CreditCardRowUi>
        {!taxNumberField && (
          <CreditCardFieldUi>
            <CreditCardLabelUi htmlFor={mercadoPagoCardIdentificationNumberId}>
              {t('components.payment_methods.identification_number')}
            </CreditCardLabelUi>
            <CreditCardInputWrapperUi
              hasErrors={Boolean(
                errors.identificationNumber ||
                  taxNumberOptions.errors.length > 0,
              )}
            >
              <CreditCardInputUi
                id={mercadoPagoCardIdentificationNumberId}
                value={taxNumberOptions.value}
                onChange={handleTaxNumberChange}
              />
            </CreditCardInputWrapperUi>
            {(errors.identificationNumber || taxNumberOptions.errors) && (
              <FieldErrors
                errors={
                  errors.identificationNumber
                    ? [errors.identificationNumber]
                    : taxNumberOptions.errors
                }
              />
            )}
          </CreditCardFieldUi>
        )}
      </CreditCardRowUi>
      <select
        id={formOptions.issuer.id}
        name="issuer"
        style={{ display: 'none' }}
      />
      {areInstallmentsVisible ? (
        <CreditCardRowUi>
          <CreditCardFieldUi>
            <CreditCardLabelUi htmlFor={formOptions.installments.id}>
              {t('components.payment_methods.installments')}
            </CreditCardLabelUi>
            <CreditCardSelectUi
              id={formOptions.installments.id}
              name="installments"
            />
          </CreditCardFieldUi>
        </CreditCardRowUi>
      ) : (
        <select
          id={formOptions.installments.id}
          name="installments"
          style={{ display: 'none' }}
        />
      )}
      <input
        value={cardNumber}
        id={formOptions.cardNumber.id}
        type="hidden"
        ref={cardNumberRef}
      />
      <input
        value={month}
        id={formOptions.cardExpirationMonth.id}
        type="hidden"
        ref={monthRef}
      />
      <input
        value={year}
        id={formOptions.cardExpirationYear.id}
        type="hidden"
        ref={yearRef}
      />
      <input
        id={formOptions.identificationNumber.id}
        type="hidden"
        value={getCleanIdentificationNumberValue(taxNumberOptions.value)}
        ref={identificationNumberRef}
      />
      <input
        type="hidden"
        value={taxNumberOptions.identificationType}
        id={formOptions.identificationType.id}
      />
    </ErrorBoundary>
  )
}

export default MercadoPagoCreditCard
