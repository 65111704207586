import React, { createContext, useContext, useState } from 'react'
import FieldIconUi from 'common/components/entities/Field/FieldIconUi'
import CommonField from 'common/components/entities/Field/FieldUi'
import FieldWrapperUi from 'common/components/entities/Field/FieldWrapperUi'
import { OldFieldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { MarginType } from 'common/types/styleTypes'
import { extractMarginFromOldStyles } from 'common/utils/styleUtilsNew'
import FieldErrors from 'publisher/components/FieldErrors'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { CustomTaxNumberOptionsKeys } from 'publisher/pages/offer-page/constants/custom-tax-number-options'
import { getCleanIdentificationNumberValue } from 'publisher/pages/offer-page/utils/getCleanIdentificationNumberValue'
import { useCustomTaxNumber } from '../../../hooks/useCustomTaxNumber'

type MercadoPagoTaxNumberProps = {
  entity: OldFieldEntityInterface
  type: CustomTaxNumberOptionsKeys
}

const MercadoPagoTaxNumberContext =
  createContext<OldFieldEntityInterface | null>(null)

function MercadoPagoTaxNumber({ entity, type }: MercadoPagoTaxNumberProps) {
  const taxNumberOptions = useCustomTaxNumber(type)
  const iconColor = entity.styles.color ? entity.styles.color : 'initial'
  const mobileIconColor = entity.mobileStyles.color
    ? entity.mobileStyles.color
    : iconColor
  const margin = extractMarginFromOldStyles(entity.styles) as MarginType
  const mobileMargin = extractMarginFromOldStyles(
    entity.mobileStyles,
  ) as MarginType

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    taxNumberOptions.change(event.target.value)
    //we need clean numbers for mp sdk
  }

  return (
    <StyleWrapper
      id={entity.options.attrId}
      margin={margin}
      appearance={entity.options.appearance || { desktop: true, mobile: true }}
      mobileMargin={mobileMargin}
    >
      <FieldWrapperUi>
        {entity.options.iconClassName && (
          <FieldIconUi
            color={iconColor}
            mobileColor={mobileIconColor}
            fontSize={entity.styles.fontSize}
            mobileFontSize={entity.mobileStyles && entity.mobileStyles.fontSize}
            className={entity.options.iconClassName}
          />
        )}
        <MercadoPagoTaxNumberContext.Provider value={entity}>
          <TaxNumberField
            errors={taxNumberOptions.errors}
            onChange={handleChange}
          />
          <input
            type="hidden"
            id="mercado-card-identification-number"
            value={getCleanIdentificationNumberValue(taxNumberOptions.value)}
          />
        </MercadoPagoTaxNumberContext.Provider>
        <FieldErrors errors={taxNumberOptions.errors} />
      </FieldWrapperUi>
    </StyleWrapper>
  )
}

function TaxNumberField(props: {
  errors: string[]
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}) {
  const entity = useContext(MercadoPagoTaxNumberContext)

  if (!entity) {
    return null
  }

  return (
    // @ts-ignore
    <CommonField
      type="text"
      padding={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 12,
        paddingBottom: 12,
      }} // this was hardcoded in the old InputUi
      name={entity.options.slug}
      hasIcon={!!entity.options.iconClassName}
      placeholderColor={entity.options.placeholderColor}
      mobilePlaceholderColor={entity?.mobileOptions?.placeholderColor}
      placeholder={entity.options.placeholder}
      hasErrors={props.errors.length > 0}
      border={{
        type: entity.options?.borderType,
        style: entity.styles?.borderStyle,
        width: entity.styles.borderWidth
          ? parseInt(entity.styles.borderWidth)
          : undefined,
        color: entity.styles?.borderColor,
        radius: {
          borderTopLeftRadius: parseInt(entity.styles.borderTopLeftRadius),
          borderTopRightRadius: parseInt(entity.styles.borderTopRightRadius),
          borderBottomRightRadius: parseInt(
            entity.styles.borderBottomRightRadius,
          ),
          borderBottomLeftRadius: parseInt(
            entity.styles.borderBottomLeftRadius,
          ),
        },
      }}
      fontSize={
        entity.styles.fontSize ? parseInt(entity.styles.fontSize) : undefined
      }
      mobileFontSize={
        entity.mobileStyles.fontSize
          ? parseInt(entity.mobileStyles.fontSize)
          : undefined
      }
      mobileBorder={{
        type: entity?.mobileOptions?.borderType,
        style: entity?.mobileStyles?.borderStyle,
        width: entity?.mobileStyles.borderWidth
          ? parseInt(entity?.mobileStyles.borderWidth)
          : undefined,
        color: entity.styles?.borderColor,
      }}
      {...props}
    />
  )
}

export default MercadoPagoTaxNumber
