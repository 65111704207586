import { CustomerTypeEnum } from 'common/enums/CustomerTypeEnum'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { OfferInterface, OneShotPlan } from 'common/types/OfferInterface'
import { ProductInterface } from 'common/types/entities/ProductInterface'
import {
  cashOnDeliveryInstructionPlaceholder,
  customerTypePlaceholder,
} from '../../reducers/paymentReducer'
import { PaymentState } from '../index'

const deadlineTimestampPlaceholder = '%DEADLINE_TIMESTAMP%'
const deadlineExpirationUrlPlaceholder = '%DEADLINE_EXPIRATION_URL%'

export function getDlocalDocumentNumber(paymentState: PaymentState) {
  return paymentState.dlocalDocumentNumber
}

export function getMercadoPagoPublicKey(paymentState: PaymentState) {
  return paymentState.mercadoPagoPublicKey
}

export function getPurchaseProcessId(paymentState: PaymentState) {
  return paymentState.purchaseProcessId
}

export function getPaymentMethods(paymentState: PaymentState) {
  try {
    return JSON.parse(paymentState.paymentMethods) as PaymentMethodEnum[]
  } catch (e) {
    return []
  }
}

function getFirstPaymentMethod(paymentState: PaymentState) {
  const paymentMethods = getPaymentMethods(paymentState)

  if (paymentMethods.length > 0) {
    return paymentMethods[0]
  }

  return null
}

export function getActivePaymentMethod(paymentState: PaymentState) {
  return (
    paymentState.customerPaymentMethod || getFirstPaymentMethod(paymentState)
  )
}

export function getActivePricePlan(state: PaymentState) {
  const checkedPlanId = getCheckedPricePlanId(state)
  try {
    const offer: OfferInterface = JSON.parse(state.offer)
    if (!state.checkedPlanId) {
      return offer.pricePlans[0]
    }

    return offer.pricePlans.find(plan => plan.id === checkedPlanId)
  } catch (e) {
    return null
  }
}

export function getPricePlans(state: PaymentState) {
  try {
    const offer: OfferInterface = JSON.parse(state.offer)
    if (!state.checkedPlanId) {
      return []
    }

    return offer.pricePlans
  } catch (e) {
    return []
  }
}

export function getCheckedBump(state: PaymentState) {
  try {
    const bump: OfferInterface<OneShotPlan> = JSON.parse(state.offerBump)

    if (!bump) {
      return
    }
    const checkedBumpId = getCheckedBumpId(state)

    if (!checkedBumpId) {
      return
    }

    if (bump.id !== checkedBumpId) {
      return
    }

    return bump
  } catch (e) {
    return
  }
}

export function getCheckedPricePlanId(state: PaymentState) {
  return Number(state.checkedPlanId)
}

export function getCheckedCoupon(state: PaymentState) {
  return state.checkedCoupon
}

export function getCheckedBumpId(state: PaymentState) {
  return state.checkedBumpId
}

function getCheckedBumpIds(state: PaymentState) {
  if (state.checkedBumpsIds.length > 0) {
    return state.checkedBumpsIds
  }

  return []
}

export function isOnlyPersonalAvailable(state: PaymentState) {
  return state.isOnlyPersonalAvailable
}

export function getOfferOwnerCountry(state: PaymentState) {
  return state.offerOwnerCountry
}

export function isCustomerPersonal(state: PaymentState) {
  return state.customerType === CustomerTypeEnum.Personal
}

export function getCustomerType(state: PaymentState) {
  return state.customerType === customerTypePlaceholder
    ? null
    : (state.customerType as CustomerTypeEnum)
}

export function getProduct(state: PaymentState) {
  try {
    return JSON.parse(state.product) as ProductInterface
  } catch (e) {
    return null
  }
}

export function getProductQuantity(state: PaymentState) {
  return state.productQuantity
}

export function getProductActiveVariant(state: PaymentState) {
  return state.productActiveVariant
}

export function getTwoStepPaymentFormStepType(state: PaymentState) {
  return state.twoStepPaymentFormStepType
}

export const getDeadlineTime = ({ deadlineTime }: PaymentState) => {
  if (deadlineTime === deadlineTimestampPlaceholder) {
    return null
  }

  return parseInt(deadlineTime) * 1000
}

export const getDeadlineExpirationUrl = ({
  deadlineExpirationUrl,
}: PaymentState) => {
  if (deadlineExpirationUrl === deadlineExpirationUrlPlaceholder) {
    return null
  }

  return deadlineExpirationUrl
}

export function getCashOnDeliveryInstruction(state: PaymentState) {
  return state.cashOnDeliveryInstruction ===
    cashOnDeliveryInstructionPlaceholder
    ? null
    : state.cashOnDeliveryInstruction
}

export function getXenditPublicKey(paymentState: PaymentState) {
  return paymentState.xenditPublicKey
}

const paymentSelectors = {
  getDlocalDocumentNumber,
  getPurchaseProcessId,
  getPaymentMethods,
  getActivePricePlan,
  getCheckedPricePlanId,
  getPricePlans,
  getCheckedCoupon,
  getCheckedBumpIds,
  getActivePaymentMethod,
  isCustomerPersonal,
  getProduct,
  getProductQuantity,
  getProductActiveVariant,
  getTwoStepPaymentFormStepType,
  getCashOnDeliveryInstruction,
  getDeadlineTime,
  getDeadlineExpirationUrl,
}

export default paymentSelectors
