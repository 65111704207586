import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'

const brazilianTaxNumberOptions = {
  personalIdentificationType: 'CPF',
  companyIdentificationType: 'CNPJ',
  detectIdentificationType: function (value: string) {
    if (value.length > 11) {
      return this.companyIdentificationType
    } else {
      return this.personalIdentificationType
    }
  },
}

const columbianCompanyRegex = new RegExp(/\d*-\d$/g)

const columbianTaxNumberOptions = {
  personalIdentificationType: 'CC',
  companyIdentificationType: 'NIT',
  detectIdentificationType: function (value: string) {
    if (columbianCompanyRegex.test(value)) {
      return this.companyIdentificationType
    } else {
      return this.personalIdentificationType
    }
  },
}

const mexicanTaxNumberOptions = {
  personalIdentificationType: '',
  companyIdentificationType: '',
  detectIdentificationType: function (value: string) {
    return this.personalIdentificationType
  },
}

const argentinianTaxNumberOptions = {
  personalIdentificationType: 'DNI',
  companyIdentificationType: 'DNI',
  detectIdentificationType: function (value: string) {
    return this.personalIdentificationType
  },
}

const chileanTaxNumberOptions = {
  personalIdentificationType: 'RUT',
  companyIdentificationType: 'RUT',
  detectIdentificationType: function (value: string) {
    return this.personalIdentificationType
  },
}

export type CustomTaxNumberOptionsKeys =
  | PaymentMethodEnum.DlocalCard
  | PaymentMethodEnum.DlocalPix
  | PaymentMethodEnum.DlocalBoleto
  | PaymentMethodEnum.MercadoPagoBoleto
  | PaymentMethodEnum.MercadoPagoPix
  | PaymentMethodEnum.MercadoPagoCard
  | PaymentMethodEnum.MercadoPagoBrCard
  | PaymentMethodEnum.MercadoPagoBrBoleto
  | PaymentMethodEnum.MercadoPagoBrPix
  | PaymentMethodEnum.MercadoPagoCoCard
  | PaymentMethodEnum.MercadoPagoMxCard
  | PaymentMethodEnum.MercadoPagoArCard
  | PaymentMethodEnum.MercadoPagoClCard

export const customTaxNumberOptions: Record<
  CustomTaxNumberOptionsKeys,
  {
    personalIdentificationType: string
    companyIdentificationType: string
    detectIdentificationType: (value: string) => string
  }
> = {
  [PaymentMethodEnum.DlocalCard]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.DlocalPix]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.DlocalBoleto]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoBoleto]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoPix]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoCard]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoBrCard]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoBrBoleto]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoBrPix]: brazilianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoCoCard]: columbianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoMxCard]: mexicanTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoArCard]: argentinianTaxNumberOptions,
  [PaymentMethodEnum.MercadoPagoClCard]: chileanTaxNumberOptions,
}
