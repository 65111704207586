import React from 'react'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { useDelay } from 'publisher/hooks/useDelay'
import useManagement, { selectors } from 'publisher/hooks/useManagement'
import usePayment, {
  selectors as paymentSelectors,
} from 'publisher/hooks/usePayment'
import BaseButton from '../BaseButton'
import CashOnDeliveryPaymentButton from './CashOnDeliveryPaymentButton'
import DLocalBoletoAndPixPaymentButton from './DLocalBoletoAndPixPaymentButton'
import DLocalPaymentButton from './DLocalPaymentButton'
import FlutterwavePaymentButton from './FlutterwavePaymentButton'
import GocardlessPaymentButton from './GocardlessPaymentButton'
import MercadoPagoCreditCardPaymentButton from './MercadoPagoCreditCardPaymentButton'
import MercadoPagoPixAndBoletoPaymentButton from './MercadoPagoPixAndBoletoPaymentButton'
import MolliePaymentButton from './MolliePaymentButton'
import PaypalPaymentButton from './PaypalPaymentButton'
import PaystackPaymentButton from './PaystackPaymentButton'
import RazorpayPaymentButton from './RazorpayPaymentButton'
import StripeBancontactPaymentButton from './StripeBancontactPaymentButton'
import StripeCardPaymentButton from './StripeCardPaymentButton'
import StripeIdealPaymentButton from './StripeIdealPaymentButton'
import XenditPaymentButton from './XenditCardPaymentButton'

function PaymentButton({ entity }) {
  const { styles, mobileStyles, options, mobileOptions } = entity
  const isScreenshotMode = useManagement(selectors.isScreenshotMode)
  const paymentMethod = usePayment(paymentSelectors.getActivePaymentMethod)
  const isVisible = useDelay(entity.options.delay)
  const isSsr = typeof window === 'undefined'
  const isPreview = useManagement(selectors.isPreviewMode)

  if (isScreenshotMode || isSsr || isPreview) {
    return (
      <BaseButton
        handleClick={() => {}}
        disabled={false}
        styles={styles}
        mobileStyles={mobileStyles}
        attrId={options.attrId}
        text={options.text}
        borderType={options.borderType}
        mobileBorderType={mobileOptions.borderType}
        appearance={options.appearance}
        subText={options.subText}
        subTextColor={options.subTextColor}
        subTextFontSize={options.subTextFontSize}
        subTextFontWeight={options.subTextFontWeight}
        subTextFontStyle={options.subTextFontStyle}
        subTextFontFamily={options.subTextFontFamily}
        mobileSubTextColor={mobileOptions.subTextColor}
        mobileSubTextFontSize={mobileOptions.subTextFontSize}
        mobileSubTextFontWeight={mobileOptions.subTextFontWeight}
        mobileSubTextFontStyle={mobileOptions.subTextFontStyle}
        mobileSubTextFontFamily={mobileOptions.subTextFontFamily}
        iconClassNameBefore={options.iconClassNameBefore}
        iconClassNameAfter={options.iconClassNameAfter}
        hoverStyles={options?.hover}
        hoverMobileStyles={mobileOptions?.hover}
      />
    )
  }

  if (!isVisible) {
    return <div />
  }

  switch (paymentMethod) {
    case PaymentMethodEnum.PaypalSoap:
      return <PaypalPaymentButton entity={entity} />
    case PaymentMethodEnum.StripeCard:
      return <StripeCardPaymentButton entity={entity} />
    case PaymentMethodEnum.StripeIDeal:
      return <StripeIdealPaymentButton entity={entity} />
    case PaymentMethodEnum.StripeBancontact:
      return <StripeBancontactPaymentButton entity={entity} />
    case PaymentMethodEnum.DlocalCard:
      return <DLocalPaymentButton entity={entity} />
    case PaymentMethodEnum.DlocalBoleto:
    case PaymentMethodEnum.DlocalPix:
      return <DLocalBoletoAndPixPaymentButton entity={entity} />
    case PaymentMethodEnum.MercadoPagoCard:
    case PaymentMethodEnum.MercadoPagoBrCard:
    case PaymentMethodEnum.MercadoPagoCoCard:
    case PaymentMethodEnum.MercadoPagoMxCard:
    case PaymentMethodEnum.MercadoPagoArCard:
    case PaymentMethodEnum.MercadoPagoClCard:
      return <MercadoPagoCreditCardPaymentButton entity={entity} />
    case PaymentMethodEnum.MercadoPagoPix:
    case PaymentMethodEnum.MercadoPagoBoleto:
    case PaymentMethodEnum.MercadoPagoBrPix:
    case PaymentMethodEnum.MercadoPagoBrBoleto:
      return <MercadoPagoPixAndBoletoPaymentButton entity={entity} />
    case PaymentMethodEnum.RazorpayCard:
      return <RazorpayPaymentButton entity={entity} />
    case PaymentMethodEnum.Flutterwave:
      return <FlutterwavePaymentButton entity={entity} />
    case PaymentMethodEnum.Mollie:
      return <MolliePaymentButton entity={entity} />
    case PaymentMethodEnum.Gocardless:
      return <GocardlessPaymentButton entity={entity} />
    case PaymentMethodEnum.CashOnDelivery:
      return <CashOnDeliveryPaymentButton entity={entity} />
    case PaymentMethodEnum.XenditIdCard:
    case PaymentMethodEnum.XenditPhCard:
      return <XenditPaymentButton entity={entity} />
    case PaymentMethodEnum.PaystackGhCard:
    case PaymentMethodEnum.PaystackKeCard:
    case PaymentMethodEnum.PaystackNgCard:
    case PaymentMethodEnum.PaystackZaCard:
      return <PaystackPaymentButton entity={entity} />
    default:
      return <div />
  }
}

export default PaymentButton
