import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreditCardFieldUi from '../../shared/ui/CreditCardFieldUi'
import CreditCardLabelUi from '../../shared/ui/CreditCardLabelUi'
import CreditCardRowUi from '../../shared/ui/CreditCardRowUi'
import creditCardBaseElementOptions from './constants/creditCardBaseElementOptions'
import {
  stripeCardExpirationId,
  stripeCardNumberId,
  stripeCardSecurityCodeId,
} from './constants/creditCardFieldIds'
import StripeErrorUi from './ui/StripeErrorUi'

function CreditCard() {
  const { t } = useTranslation('common')
  const [isCardNumberError, setIsCardNumberError] = useState(false)
  const [isCardExpirationError, setIsCardExpirationError] = useState(false)
  const [isCardCvcError, setIsCardCvcError] = useState(false)
  //to prevent zoom on focus in mobile ios
  return (
    <React.Fragment>
      <CreditCardRowUi flexDirectionColumn>
        <CreditCardFieldUi>
          <CreditCardLabelUi htmlFor={stripeCardNumberId}>
            {t('components.payment_methods.card_number')}
          </CreditCardLabelUi>
          <CardNumberElement
            options={{
              ...creditCardBaseElementOptions,
              showIcon: true,
            }}
            className="Card"
            onChange={({ error }) => {
              if (!error) {
                if (isCardNumberError) {
                  setIsCardNumberError(false)
                }
              } else if (error.message) {
                setIsCardNumberError(true)
              }
            }}
            id={stripeCardNumberId}
          />
        </CreditCardFieldUi>
        {isCardNumberError && (
          <StripeErrorUi>
            {t('components.payment_methods.stripe.invalid_card_number')}
          </StripeErrorUi>
        )}
      </CreditCardRowUi>
      <CreditCardRowUi>
        <CreditCardFieldUi width="50%">
          <CreditCardLabelUi htmlFor={stripeCardExpirationId}>
            {t('components.payment_methods.expiration')}
          </CreditCardLabelUi>
          <CardExpiryElement
            options={creditCardBaseElementOptions}
            id={stripeCardExpirationId}
            className="Card"
            onChange={({ error }) => {
              if (!error) {
                if (isCardExpirationError) {
                  setIsCardExpirationError(false)
                }
              } else if (error.message) {
                setIsCardExpirationError(true)
              }
            }}
          />
          {isCardExpirationError && (
            <StripeErrorUi>
              {t(
                'components.payment_methods.stripe.invalid_card_expiration_date',
              )}
            </StripeErrorUi>
          )}
        </CreditCardFieldUi>
        <CreditCardFieldUi width="50%">
          <CreditCardLabelUi htmlFor={stripeCardSecurityCodeId}>
            {t('components.payment_methods.cvc')}
          </CreditCardLabelUi>
          <CardCvcElement
            options={creditCardBaseElementOptions}
            id={stripeCardSecurityCodeId}
            className="Card"
            onChange={({ error }) => {
              if (!error) {
                if (isCardCvcError) {
                  setIsCardCvcError(false)
                }
              } else if (error.message) {
                // https://systeme-team.slack.com/archives/C6UURE03D/p1668177169203109
                setIsCardCvcError(true)
              }
            }}
          />
          {isCardCvcError && (
            <StripeErrorUi>
              {t(
                'components.payment_methods.stripe.invalid_card_security_code',
              )}
            </StripeErrorUi>
          )}
        </CreditCardFieldUi>
      </CreditCardRowUi>
    </React.Fragment>
  )
}

export default CreditCard
