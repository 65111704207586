import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { usePage as useTypedPage } from 'publisher/store'
import typedPageSelectors from 'publisher/store/page/pageSelectors'
import * as exceptions from '../../common/errors'
import { SHOW_TWO_STEP_PAYMENT_FORM_STEP_PAYMENT } from '../actionTypes'
import { optInFail } from '../actions/optInActions'
import { twoStepOptIn } from '../api/payment'
import { Query, QueryKeys } from '../components/core/MatchMedia'
import { getDateTimeField, getFieldsBySlugs } from '../reducers/optInReducer'
import { getAscendantPopupId } from '../reducers/pageReducer'
import useManagement, { selectors as managementSel } from './useManagement'
import useOptIn from './useOptIn'
import usePage, { selectors as pageSelectors } from './usePage'
import usePayment, { selectors as paymentSelectors } from './usePayment'

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

function useTwoStepOptInSubmit(optInStepEntity) {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const isTemplate = usePage(pageSelectors.isTemplate)
  const [errors, setErrors] = useState([])
  const pageId = usePage(pageSelectors.getPageId)
  const twoStepPaymentFormFieldsSlugs = usePage(page =>
    pageSelectors.findEntityDescendantsFieldsSlugs(page, optInStepEntity),
  )
  const companyNameField = usePage(pageSelectors.getCompanyNameField)
  const companyVatField = usePage(pageSelectors.getCompanyVatField)
  const isCustomerPersonal = usePayment(paymentSelectors.isCustomerPersonal)
  const fields = useOptIn(state =>
    getFieldsBySlugs(state, twoStepPaymentFormFieldsSlugs),
  )
  const dateTimeField = useOptIn(getDateTimeField)
  const popupId = usePage(page => getAscendantPopupId(page, optInStepEntity))
  const confirmedExplicitConsentsIds = useManagement(
    managementSel.getConfirmedExplicitConsentsIds,
  )

  const mandatoryExplicitConsents = useTypedPage(p =>
    typedPageSelectors.findEntityDescendantsExplicitConsents(
      p,
      optInStepEntity.id,
    ),
  )

  const dispatch = useDispatch()

  const submit = async e => {
    e.preventDefault()
    if (isTemplate) {
      return
    }

    const errors = mandatoryExplicitConsents.reduce(
      (errors, explicitConsent) => {
        return confirmedExplicitConsentsIds.includes(explicitConsent.id)
          ? errors
          : [...errors, explicitConsent.options.errorText]
      },
      [],
    )

    if (errors.length > 0) {
      setErrors(errors)
      return
    }

    setLoading(true)

    if (isCustomerPersonal) {
      if (companyNameField) {
        delete fields[companyNameField.options.slug]
      }

      if (companyVatField) {
        delete fields[companyVatField.options.slug]
      }
    }
    try {
      await twoStepOptIn(
        pageId,
        fields,
        dateTimeField,
        timeZone,
        popupId,
        window.matchMedia(Query[QueryKeys.DESKTOP]).matches,
        optInStepEntity.id,
        confirmedExplicitConsentsIds,
      )

      dispatch({
        type: SHOW_TWO_STEP_PAYMENT_FORM_STEP_PAYMENT,
      })

      setLoading(false)
    } catch (error) {
      if (error instanceof exceptions.BadRequest) {
        setErrors(error.response.data.errors.common)
        dispatch(optInFail({ fields: error.response.data.errors.fields }))
      } else if (error instanceof exceptions.NetworkError) {
        setErrors([t('core.errors.no_connection')])
      } else {
        window.Rollbar.error(`Opt-in error`, error)
      }
      setErrors(errors)
      setLoading(false)
    }
  }

  return [isLoading, errors, submit]
}

export default useTwoStepOptInSubmit
