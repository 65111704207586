import PropTypes from 'prop-types'
import React, { useLayoutEffect, useRef, useState } from 'react'
import provideMobileStyles from 'common/hoc/provideMobileStyles'
import * as urlUtils from 'common/utils/urlUtils'
import CurrentLangUi from './ui/LanguageSwitcherCurrentLangUi'
import DropdownUi from './ui/LanguageSwitcherDropdownUi'
import ItemsLinkUi from './ui/LanguageSwitcherItemsLinkUi'
import ItemsUi from './ui/LanguageSwitcherItemsUi'
import LanguageSwitcherUi from './ui/LanguageSwitcherUi'

export const localeKeys = {
  en: 'en',
  fr: 'fr',
  es: 'es',
  it: 'it',
  pt: 'pt',
  de: 'de',
  nl: 'nl',
  ru: 'ru',
  jp: 'jp', // why not ja? @see https://systeme-team.slack.com/archives/G61JS7GDC/p1684234537974629
  ar: 'ar',
  tr: 'tr',
  zh: 'zh',
}

export const localeNames = {
  [localeKeys.en]: 'English',
  [localeKeys.fr]: 'Français',
  [localeKeys.es]: 'Español',
  [localeKeys.it]: 'Italiano',
  [localeKeys.pt]: 'Português',
  [localeKeys.de]: 'Deutsch',
  [localeKeys.nl]: 'Nederlands',
  [localeKeys.ru]: 'Русский',
  [localeKeys.jp]: '日本語',
  [localeKeys.ar]: 'عربي',
  [localeKeys.tr]: 'Türkçe',
  [localeKeys.zh]: '中國人',
}

function LanguageSwitcher({
  currentLanguage,
  styles,
  mobileStyles,
  redirectPath,
  excludedLanguages,
}) {
  const dropdownContainer = useRef()
  const [isOpened, setOpened] = useState(false)
  const toggleMenu = () => {
    setOpened(!isOpened)
  }
  const {
    marginTop,
    marginLeft,
    marginRight,
    marginBottom,
    ...currentLanguageStyles
  } = styles
  const {
    marginTop: mobileMarginTop,
    marginLeft: mobileMarginLeft,
    marginRight: mobileMarginRight,
    marginBottom: mobileMarginBottom,
    ...currentLanguageMobileStyles
  } = mobileStyles

  useLayoutEffect(() => {
    window.onclick = function (event) {
      if (
        !event.target.matches('.dropdown') &&
        dropdownContainer.current &&
        !dropdownContainer.current.contains(event.target)
      ) {
        if (isOpened) {
          setOpened(false)
        }
      }
    }
  }, [isOpened])

  const getUrl = locale => {
    if (redirectPath === '') {
      return locale === localeKeys.en ? `/` : `/${locale}`
    }

    const clearRedirectPath =
      urlUtils.trimLeadingAndTrailingSlashes(redirectPath)

    return locale === localeKeys.en
      ? `/${clearRedirectPath}`
      : `/${locale}/${clearRedirectPath}`
  }

  return (
    <LanguageSwitcherUi
      styles={{ marginTop, marginLeft, marginRight, marginBottom }}
      mobileStyles={{
        marginTop: mobileMarginTop,
        marginLeft: mobileMarginLeft,
        marginRight: mobileMarginRight,
        marginBottom: mobileMarginBottom,
      }}
    >
      <CurrentLangUi
        styles={currentLanguageStyles}
        mobileStyles={currentLanguageMobileStyles}
        className="dropdown"
        onClick={toggleMenu}
      >
        {currentLanguage.charAt(0).toUpperCase() + currentLanguage.slice(1)}
      </CurrentLangUi>
      <DropdownUi
        onClick={toggleMenu}
        className="dropdown"
        styles={currentLanguageStyles}
        mobileStyles={currentLanguageMobileStyles}
      />
      {
        <ItemsUi
          ref={dropdownContainer}
          fontFamily={
            currentLanguageMobileStyles.fontFamily ||
            currentLanguageStyles.fontFamily
          }
          isOpened={isOpened}
        >
          {Object.keys(localeNames)
            .filter(countryKey => countryKey !== currentLanguage)
            .filter(countryKey => !excludedLanguages.includes(countryKey))
            .map(countryKey => (
              <ItemsLinkUi key={countryKey} href={getUrl(countryKey)}>
                {localeNames[countryKey]}
              </ItemsLinkUi>
            ))}
        </ItemsUi>
      }
    </LanguageSwitcherUi>
  )
}

LanguageSwitcher.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  styles: PropTypes.shape({
    marginTop: PropTypes.string,
    marginRight: PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
    lineHeight: PropTypes.string,
  }),
  mobileStyles: PropTypes.shape({
    marginTop: PropTypes.string,
    marginRight: PropTypes.string,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
    lineHeight: PropTypes.string,
  }),
  redirectPath: PropTypes.string,
  excludedLanguages: PropTypes.arrayOf(PropTypes.string),
}

LanguageSwitcher.defaultProps = {
  redirectPath: '',
  excludedLanguages: [],
}

export default provideMobileStyles(LanguageSwitcher)
