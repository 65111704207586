export enum PaymentMethodEnum {
  StripeCard = 'stripe:card',
  StripeIDeal = 'stripe:ideal',
  StripeSepaDebit = 'stripe:sepa_debit',
  StripeBancontact = 'stripe:bancontact',
  PaypalSoap = 'paypal-soap',
  DlocalCard = 'dlocal:card',
  DlocalBoleto = 'dlocal:boleto',
  DlocalPix = 'dlocal:pix',
  MercadoPagoCard = 'mercadopago:card',
  MercadoPagoPix = 'mercadopago:pix',
  MercadoPagoBrCard = 'mercadopago-br:card',
  MercadoPagoBrPix = 'mercadopago-br:pix',
  MercadoPagoBrBoleto = 'mercadopago-br:boleto',
  MercadoPagoCoCard = 'mercadopago-co:card',
  MercadoPagoMxCard = 'mercadopago-mx:card',
  MercadoPagoArCard = 'mercadopago-ar:card',
  MercadoPagoClCard = 'mercadopago-cl:card',
  MercadoPagoBoleto = 'mercadopago:boleto',
  RazorpayCard = 'razorpay',
  Flutterwave = 'flutterwave',
  Mollie = 'mollie',
  Gocardless = 'gocardless',
  CashOnDelivery = 'cash-on-delivery',
  XenditIdCard = 'xendit-id:card',
  XenditIdDd = 'xendit-id:dd',
  XenditIdEwallet = 'xendit-id:ewallet',
  XenditPhCard = 'xendit-ph:card',
  XenditPhDd = 'xendit-ph:dd',
  XenditPhEwallet = 'xendit-ph:ewallet',
  PaystackGhCard = 'paystack-gh:card',
  PaystackKeCard = 'paystack-ke:card',
  PaystackNgCard = 'paystack-ng:card',
  PaystackZaCard = 'paystack-za:card',
}

export const paymentMethodsWithPersonalTaxNumber = [
  PaymentMethodEnum.MercadoPagoCard,
  PaymentMethodEnum.MercadoPagoBoleto,
  PaymentMethodEnum.MercadoPagoPix,
  PaymentMethodEnum.MercadoPagoBrCard,
  PaymentMethodEnum.MercadoPagoBrBoleto,
  PaymentMethodEnum.MercadoPagoBrPix,
  PaymentMethodEnum.MercadoPagoCoCard,
  PaymentMethodEnum.MercadoPagoMxCard,
  PaymentMethodEnum.MercadoPagoArCard,
  PaymentMethodEnum.MercadoPagoClCard,
  PaymentMethodEnum.DlocalCard,
  PaymentMethodEnum.DlocalBoleto,
  PaymentMethodEnum.DlocalPix,
]

export const mercadoCreditCardTypes = [
  PaymentMethodEnum.MercadoPagoCard,
  PaymentMethodEnum.MercadoPagoBrCard,
  PaymentMethodEnum.MercadoPagoCoCard,
  PaymentMethodEnum.MercadoPagoMxCard,
  PaymentMethodEnum.MercadoPagoArCard,
  PaymentMethodEnum.MercadoPagoClCard,
]
