import { CHANGE_LECTURE_SESSION_ID } from 'publisher/actionTypes'

const lectureSessionLoggingEnabledPlaceholder =
  '%LECTURE_SESSION_LOGGING_ENABLED%'
const lectureIdPlaceholder = '%LECTURE_ID%'

const defaultState = {
  lectureSessionId: null,
  lectureSessionLoggingEnabled: lectureSessionLoggingEnabledPlaceholder,
  lectureId: lectureIdPlaceholder,
}

export default function (state = defaultState, action) {
  const { type, payload } = action
  switch (type) {
    case CHANGE_LECTURE_SESSION_ID:
      return {
        ...state,
        lectureSessionId: payload,
      }
    default:
      return state
  }
}

export const getLectureSessionId = state => state.lectureSessionId

export const getLectureId = state => state.lectureId

export const isLectureSessionLoggingEnabled = state => {
  if (
    state.lectureSessionLoggingEnabled ===
    lectureSessionLoggingEnabledPlaceholder
  ) {
    return false
  }

  return state.lectureSessionLoggingEnabled
}

export const selectors = {
  getLectureId,
  getLectureSessionId,
  isLectureSessionLoggingEnabled,
}
